<script setup>
import { ref } from 'vue'
import { loadGridScoreDerivationMeasurement } from '@/composables/charts'

import CameraBaseChart from '@/components/charts/CameraBaseChart.vue'

const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregateCameras: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    defaultCollapsed: { type: Boolean, default: false },
  },
)
const options = ref({})


async function dataLoader(cameraIds, timeSetup, abortSignal) {
  return await loadGridScoreDerivationMeasurement({
    timeResolution: timeSetup.timeResolution,
    aggregateCameras: props.aggregateCameras,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    cameraIds: cameraIds,
    chartType: 'line',
  }, abortSignal)
}

</script>

<template>
  <CameraBaseChart
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :default-collapsed="props.defaultCollapsed"
    :options="options"
    :title="props.title"
    :subtitle="props.subtitle"
  />
</template>

<style scoped>

</style>
