<script setup>
import { computed, inject, nextTick, onMounted, ref, shallowRef, watch } from 'vue'
import { useTheme } from 'vuetify'
import ChartTimeSetupPicker from '@/components/charts/ChartTimeSetupPicker.vue'
import { useLogsStore } from '@/store/logs.js'
import { Chart } from 'highcharts-vue'
import { useI18n } from 'vue-i18n'
import Highcharts from 'highcharts'
import annotations from 'highcharts/modules/annotations'
import exportData from 'highcharts/modules/export-data'
import exportingInit from 'highcharts/modules/exporting'
import xrange from 'highcharts/modules/xrange'
import noData from 'highcharts/modules/no-data-to-display'
import offlineExportingInit from 'highcharts/modules/offline-exporting'
import { getCurrentTimezone, humanReadableTimestamp } from '@/composables/datetime.js'

const { t } = useI18n()
import _ from 'lodash'
import DataPointClickDialog from '@/components/charts/DataPointClickDialog.vue'
import TriggerBarnDocumentation from '@/components/incidentTriggers/TriggerBarnDocumentation.vue'
import IncidentDisplay from '@/components/incidents/IncidentDisplay.vue'

const theme = useTheme()
xrange(Highcharts)
exportingInit(Highcharts)
offlineExportingInit(Highcharts)
exportData(Highcharts)
annotations(Highcharts)
noData(Highcharts)

const props = defineProps({
  defaultCollapsed: { type: Boolean, default: false },
  height: { type: Number, default: 400 },
  chartType: { type: String, default: 'line' },
  options: {
    type: Object, default: () => {
    },
  },
  dataLoader: {
    type: Function, default: async function() {
    },
  },
  disableControlRow: { type: Boolean, default: false },
  hideTimeResolution: { type: Boolean, default: false },
  xAxisType: { type: String, default: 'datetime' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  enableClickEvent: { type: Boolean, default: true },
  incidentAnnotationProviders: { type: Array, default: () => [] },
})

const annotationProviders = props.incidentAnnotationProviders.map(({ provider, params }) => new provider(...params))
let loadingPromiseResolve = null
let loadingPromise = null

const expanded = ref(!props.defaultCollapsed)
const barnDocumentationFirstTimestamp = ref(null)
const barnDocumentationSecondTimestamp = ref(null)
const timeSetup = inject('timeSetup', ref({
  start: Date.now() - 1000 * 60 * 60 * 24 * 4,
  end: Date.now(),
  timeResolution: 1000 * 60 * 10,
}))
const localTimeSetup = ref(timeSetup.value)
const chainTimeSetup = inject('chainTimeSetup', ref(false))
const abortController = ref(null)
const chart = ref(null)

const loading = ref(false)
watch(chainTimeSetup, () => {
  if (chainTimeSetup.value && !_.isEqual(localTimeSetup.value, timeSetup.value)) {
    localTimeSetup.value = timeSetup.value
  }
})

watch(timeSetup, () => {
  if (chainTimeSetup) {
    localTimeSetup.value = timeSetup.value
  }
}, { deep: true, immediate: true })

const allShown = ref(false)
const allHidden = ref(false)
const baseOptions = {
  noData: { style: { fontWeight: 'bold', 'fontSize': '20px' } },
  loading: {
    style: {
      opacity: 0.8,
      backgroundColor: theme.current.value.colors.surface,
    },
    showDuration: 50,
    hideDuration: 500,
  },
  credits: {
    text: 'vetvise.com',
    href: 'https://vetvise.com',
  },
  time: {
    useUTC: false,
    timezone: getCurrentTimezone(),
  },
  plotOptions: {
    series: {
      stickyTracking: false,
      groupPadding: 0,
      events: {
        hide: function() {
          allShown.value = chart.value?.chart.series.every(series => series.visible)
          allHidden.value = chart.value?.chart.series.every(series => !series.visible)
        },
        show: function() {
          allShown.value = chart.value?.chart.series.every(series => series.visible)
          allHidden.value = chart.value?.chart.series.every(series => !series.visible)

        },
      },
    },
  },
  exporting: {
    enabled: true,
    fallbackToExportServer: false,
    sourceWidth: 3000,
    sourceHeight: 500,
  },
  tooltip: {
    distance: 25,
    hideDelay: 100,
    pointFormatter: function() {
      return `<span style='color: ${this.color}'>●</span> ${this.series.name}: <b>${this.y.toPrecision(5)}</b><br>`
    },
  },
  yAxis: {
    backgroundColor: theme.current.value.colors.surface,
    labels: {
      style: {
        color: theme.current.value.colors.onSurface,
      },
    },
  },
  legend: {
    itemStyle: {
      color: theme.current.value.colors.onSurface,

    },

  },
  xAxis: {
    backgroundColor: theme.current.value.colors.surface,
    labels: {
      style: {
        color: theme.current.value.colors.onSurface,
      },
    },
    events: {
      afterSetExtremes: e => {
        annotationProviders.forEach(provider => {
          provider.buildAnnotations(e.min, e.max)
        })
      },
    },
  },
  chart: {
    style: {
      fontFamily: 'roboto',
    },
    zooming: {
      type: 'xy',
    },
    backgroundColor: theme.current.value.colors.surface,
  },
}

const showError = ref(false)

const loaded = ref(false)
const initialLoadTriggered = ref(false)
const chartData = ref(null)
const clickedDataPoint = ref(null)
const showDataPointDialog = ref(false)
const enabledAnnotationProviders = ref([])
const showBarnDocumentationTriggerDialog = ref(false)
const newlyCreatedBarnDocIncidentId = ref(null)
const showNewlyCreatedBarnDocIncidentDialog = ref(false)

const chartOptions = computed(() => {
  const options = { ...baseOptions, ...props.options, ...chartData.value }

  if (!options.chart) options.chart = {}
  if (!options.xAxis) options.xAxis = {}

  if (props.height) options.chart.height = props.height
  options.chart.type = props.chartType

  options.xAxis.type = props.xAxisType
  options.xAxis.min = localTimeSetup.value.start
  options.xAxis.max = localTimeSetup.value.end

  options.title = { text: props.title, style: { color: theme.current.value.colors.onSurface } }
  options.subtitle = { text: props.subtitle, style: { color: theme.current.value.colors.onSurface } }

  if (props.enableClickEvent) {
    if (!options.plotOptions) options.plotOptions = {}
    if (!options.plotOptions.series) options.plotOptions.series = {}
    if (!options.plotOptions.series.point) options.plotOptions.series.point = {}
    options.plotOptions.series.point.events = {
      click: function() {
        openDataPointClickDialog(this)
      },
    }
  }

  options.annotations = incidentAnnotations.value
  options.annotations = options.annotations.concat(barnDocAnnotations.value)
  return options
})

const barnDocAnnotations = computed(() => {
  const annotations = []
  if(barnDocumentationFirstTimestamp.value) {
    annotations.push({
      draggable: '',
      allowOverlap: true,
      zIndex: 1,
      shapes: [{
        type: 'path',
        stroke: 'red',
        strokeWidth: 2,
        points: [
          { xAxis: 0, yAxis: 0, x: barnDocumentationFirstTimestamp.value, y: Number.MIN_SAFE_INTEGER },
          { xAxis: 0, yAxis: 0, x: barnDocumentationFirstTimestamp.value, y: Number.MAX_SAFE_INTEGER },
        ],
      }],
    })
  }
  if(barnDocumentationSecondTimestamp.value) {
    annotations.push({
      draggable: '',
      allowOverlap: true,
      zIndex: 1,
      shapes: [{
        type: 'path',
        stroke: 'red',
        strokeWidth: 2,
        points: [
          { xAxis: 0, yAxis: 0, x: barnDocumentationSecondTimestamp.value, y: Number.MIN_SAFE_INTEGER },
          { xAxis: 0, yAxis: 0, x: barnDocumentationSecondTimestamp.value, y: Number.MAX_SAFE_INTEGER },
        ],
      }],
    })
  }
  return annotations
})

const incidentAnnotations = computed(() => {
  const annotationsToAdd = []
  for (const incidentAnnotationProvider of annotationProviders) {
    const annotations = incidentAnnotationProvider.getAnnotations()
    if (!annotations) continue
    for (const annotation of annotations) {
      let tempAnnotation = { ...annotation }
      if (!enabledAnnotationProviders.value.includes(incidentAnnotationProvider.getProviderId())) {
        tempAnnotation.visible = false
      }
      if (tempAnnotation.visible === undefined) tempAnnotation.visible = true
      annotationsToAdd.push(tempAnnotation)
    }
  }
  return annotationsToAdd
})

function openDataPointClickDialog(dataPoint) {
  clickedDataPoint.value = dataPoint
  showDataPointDialog.value = true
}

watch(() => showBarnDocumentationTriggerDialog.value, () => {
  if(showBarnDocumentationTriggerDialog.value) return
  barnDocumentationSecondTimestamp.value = null
})
watch(() => loading.value, () => {
  if (loading.value) {
    chart.value?.chart?.showLoading(t('charts.loading_text'))
  } else {
    chart.value?.chart?.hideLoading()
  }
})

watch(() => localTimeSetup.value, async (value, oldValue) => {
  if (
    !initialLoadTriggered.value
    || value.start !== oldValue.start
    || value.end !== oldValue.end
    || value.timeResolution !== oldValue.timeResolution
  ) {
    if (expanded.value) {
      await nextTick()  //make sure the chart is actually rendered before loading data
      await loadData()
    } else loaded.value = false
  }
}, { deep: true })

const legendHeight = computed(() => {
  if (!chart.value?.chart?.legend) return 0
  const height = chart.value.chart.legend.legendHeight || 0
  const fullHeight = chart.value.chart.legend.fullHeight || 0
  return Math.max(0, height, fullHeight)
})

watch(legendHeight, () => {
  if (!legendHeight.value || !chart.value?.chart) return
  chart.value.chart.setSize(null, props.height + legendHeight.value)
  chart.value.chart.redraw()

}, { immediate: true })

function hideAllSeries() {
  chart.value.chart.series.forEach(series => {
    series.setVisible(false, false)
  })
  chart.value.chart.redraw()
}

function showAllSeries() {
  chart.value.chart.series.forEach(series => {
    series.setVisible(true, false)
  })
  chart.value.chart.redraw()
}

const enableAnnotationDialog = ref(false)
const annotationDialogComponent = shallowRef(null)
const annotationDialogProps = ref(null)

function showAnnotationDialog(component, props) {
  enableAnnotationDialog.value = true
  annotationDialogComponent.value = component
  annotationDialogProps.value = props
}

async function expandChart() {
  expanded.value = true
  if (!initialLoadTriggered.value || !loaded.value) {
    await nextTick()  //make sure the chart is actually rendered before loading data
    await loadData()
  }
}

async function abortLoading() {
  if (abortController.value) {
    abortController.value.abort()
    loading.value = false
    if(loadingPromise) await Promise.allSettled([loadingPromise])
  }
}

function createBarnDocumentationIncident(firstTimestamp, secondTimestamp=undefined) {
  barnDocumentationFirstTimestamp.value = firstTimestamp
  barnDocumentationSecondTimestamp.value = secondTimestamp
  showBarnDocumentationTriggerDialog.value = true
}

async function showNewBarnDocIncident(incidentId) {
  await loadAnnotations()
  barnDocumentationFirstTimestamp.value = null
  barnDocumentationSecondTimestamp.value = null
  showBarnDocumentationTriggerDialog.value = false
  newlyCreatedBarnDocIncidentId.value = incidentId
  showNewlyCreatedBarnDocIncidentDialog.value = true

}

function cancelBarnDocCreation() {
  barnDocumentationFirstTimestamp.value = null
  barnDocumentationSecondTimestamp.value = null
  showBarnDocumentationTriggerDialog.value = false
}

async function loadAnnotations(signal) {
  if (annotationProviders.length > 0) {
    for (const incidentAnnotationProvider of annotationProviders) {
      incidentAnnotationProvider.initialize(chart, highlightAnnotations, resetAnnotationHighlight, showAnnotationDialog)
      enabledAnnotationProviders.value.push(incidentAnnotationProvider.getProviderId())
      if (!signal || !signal.aborted) {
        await incidentAnnotationProvider.load(localTimeSetup.value.start, localTimeSetup.value.end, signal ? signal : new AbortController().signal)
      }
    }
  }
}

async function loadData() {
  initialLoadTriggered.value = true
  await abortLoading()
  abortController.value = new AbortController()
  const signal = abortController.value.signal
  showError.value = false
  try {
    if(loadingPromise) loadingPromiseResolve()
    loadingPromise = new Promise((resolve) => {
      loadingPromiseResolve = resolve
    })
    loading.value = true
    enabledAnnotationProviders.value = []
    chartData.value = await props.dataLoader(localTimeSetup.value, signal)
    await loadAnnotations(signal)
    loadingPromiseResolve()
  } catch (e) {
    loadingPromiseResolve()
    showError.value = true
    useLogsStore().addLogEntry({ message: 'Could not load Data for chart', error: e, tag: 'chart', level: 'ERROR' })
  }
  loaded.value = true
  loading.value = false
  allShown.value = chart.value.chart.series.every(series => series.visible || series.visible === undefined)
  allHidden.value =
    chart.value.chart.series.every(series => series.visible === false)
    && chart.value.chart.series.length > 0
}

function highlightAnnotations(annotationGroupId) {
  for (const incidentAnnotationProvider of annotationProviders) {
    incidentAnnotationProvider.highlightAnnotations(annotationGroupId)
  }
}

function resetAnnotationHighlight() {
  for (const incidentAnnotationProvider of annotationProviders) {
    incidentAnnotationProvider.resetHighlight()
  }
}

function injectChartData(data) {
  chartData.value = data
  loaded.value = true
  loading.value = false
  initialLoadTriggered.value = true
}

onMounted(async () => {
  if (expanded.value)
    if(localTimeSetup.value.start && localTimeSetup.value.end) await loadData()
})

defineExpose({ loadData, injectChartData, abortLoading })
</script>

<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      v-if="!expanded"
      justify="center"
    >
      <v-col>
        <v-layout-card class="mx-1">
          <v-card-title>{{ title }}</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
          <v-card-text>{{ $t('charts.click_to_show') }}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              variant="flat"
              color="primary"
              block
              @click="expandChart()"
            >
              {{ $t('charts.expand') }}
            </v-btn>
          </v-card-actions>
        </v-layout-card>
      </v-col>
    </v-row>


    <v-fade-transition>
      <v-layout-card
        class="mx-1"
        v-if="expanded"
        elevation="8"
      >
        <v-row>
          <v-col
            v-if="!disableControlRow"
            :cols="12"
          >
            <v-row
              class="pa-2 justify-end"
              :no-gutters="true"
            >
              <v-col
                v-if="!chainTimeSetup"
                cols="auto"
              >
                <ChartTimeSetupPicker
                  v-model="localTimeSetup"
                  :hide-time-resolution="hideTimeResolution"
                />
              </v-col>
              <v-col
                v-if="loading"
                cols="auto"
              >
                <v-btn
                  class="ma-2 rounded-pill"
                  prepend-icon="mdi-cancel"
                  @click="abortLoading"
                >
                  {{ $t('charts.abort') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!loading"
                cols="auto"
              >
                <v-btn
                  class="ma-2 rounded-pill"
                  prepend-icon="mdi-reload"
                  @click="loadData"
                >
                  {{ $t('charts.reload') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-text>
          <Chart
            ref="chart"
            :options="chartOptions"
            @mouseleave="resetAnnotationHighlight"
          />
          <v-row class="justify-space-between">
            <v-col cols="auto">
              <v-btn
                v-if="allShown"
                class="my-1"
                variant="outlined"
                style="border-radius: 4px!important;"
                size="small"
                @click="hideAllSeries"
              >
                {{ t('charts.hide_all_series') }}
              </v-btn>
              <v-btn
                v-if="allHidden"
                variant="outlined"
                style="border-radius: 4px!important;"
                size="small"
                @click="showAllSeries"
              >
                {{ t('charts.show_all_series') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="loaded"
              cols="auto"
            >
              <v-btn-toggle
                v-model="enabledAnnotationProviders"
                multiple
                rounded="0"
                density="compact"
                class="pa-0"
              >
                <v-btn
                  v-for="annotationProvider in annotationProviders"
                  :key="annotationProvider.getProviderId()"
                  style="border-radius: 4px!important;"
                  :value="annotationProvider.getProviderId()"
                  class="rounded-0 ma-1"
                  size="small"
                  active-color="primary"
                >
                  {{ annotationProvider.getProviderTitle() }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-alert
            v-if="showError"
            class="my-4"
            type="error"
            prominent
            variant="elevated"
          >
            {{ $t('charts.could_not_load') }}
          </v-alert>

          <v-row
            v-if="barnDocumentationFirstTimestamp"
            class="justify-center align-center"
          >
            <v-col cols="auto">
              <v-btn
                variant="outlined"
                color="error"
                @click="barnDocumentationFirstTimestamp = null"
              >
                Cancel annotation
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-chip
                variant="outlined"
                closable
                @click:close="() => {barnDocumentationFirstTimestamp = null; barnDocumentationSecondTimestamp = null}"
              >
                Annotation Start: {{ humanReadableTimestamp(barnDocumentationFirstTimestamp) }}
              </v-chip>
            </v-col>
            <v-col cols="auto">
              <v-btn
                variant="outlined"
                color="primary"
                @click="createBarnDocumentationIncident(barnDocumentationFirstTimestamp)"
              >
                Finish Annotation
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            variant="outlined"
            size="small"
            @click="expanded = false"
          >
            {{ $t('charts.collapse') }}
          </v-btn>
        </v-card-actions>
      </v-layout-card>
    </v-fade-transition>
  </v-container>

  <DataPointClickDialog
    v-model:show-dialog="showDataPointDialog"
    v-model:first-selected-timestamp="barnDocumentationFirstTimestamp"
    :clicked-data-point="clickedDataPoint"
    @finish-barn-documentation-incident="createBarnDocumentationIncident"
  />

  <v-dialog
    v-model="showBarnDocumentationTriggerDialog"
    max-width="800px"
  >
    <TriggerBarnDocumentation
      :pre-filled-first-timestamp="barnDocumentationFirstTimestamp"
      :pre-filled-second-timestamp="barnDocumentationSecondTimestamp"
      @saved="showNewBarnDocIncident"
      @cancel="cancelBarnDocCreation"
    />
  </v-dialog>

  <v-dialog
    v-model="showNewlyCreatedBarnDocIncidentDialog"
    max-width="800px"
  >
    <v-layout-card>
      <v-card-title>
        Barn Documentation Incident Created
      </v-card-title>
      <v-card-text>
        <IncidentDisplay :incident-id="newlyCreatedBarnDocIncidentId" />
      </v-card-text>
    </v-layout-card>
  </v-dialog>
  <!-- Dialog for annotations -->
  <v-dialog
    v-model="enableAnnotationDialog"
    max-width="1000px"
  >
    <component
      :is="annotationDialogComponent"
      v-bind="annotationDialogProps"
    />
  </v-dialog>
</template>

<style>

</style>
