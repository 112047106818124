<script setup>
import { computed, onMounted } from 'vue'
import BaseChart from '@/components/charts/BaseChart.vue'
import { useRegionStore } from '@/store/regions.js'

const regionStore = useRegionStore()
const props = defineProps({
  height: { type: Number, default: 400 },
  options: {
    type: Object, default: () => {
    },
  },
  chartType: { type: String, default: 'line' },
  dataLoader: {
    type: Function, default: async function() {
    },
  },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  disableControlRow: { type: Boolean, default: false },
  hideTimeResolution: { type: Boolean, default: false },
  pens: { type: Array, default: () => [] },
  defaultCollapsed: { type: Boolean, default: false },
})


async function localDataLoader(timeSetup, abortSignal) {
  return await props.dataLoader(penRegions.value, timeSetup, abortSignal)
}

const penRegions = computed(() => {
  if (props.pens.length > 0) return props.pens
  return regionStore.getPenRegionSpecifications()
})
const pensLoaded = computed(() => penRegions.value && penRegions.value.length > 0)

onMounted(async () => {
  await regionStore.keepRegionsLoaded()
})
</script>

<template>
  <BaseChart
    v-if="pensLoaded"
    :default-collapsed="props.defaultCollapsed"
    :height="height"
    :data-loader="localDataLoader"
    :chart-type="chartType"
    :options="options"
    :disable-control-row="props.disableControlRow"
    :hide-time-resolution="props.hideTimeResolution"
    :title="title"
    :subtitle="subtitle"
  />
</template>

<style scoped>

</style>
