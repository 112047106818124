<script setup>
import CanvasRenderer from '@/components/CanvasRenderer.vue';
import { onMounted, ref, computed, provide } from 'vue';
import { useCanvasStore } from '@/store/canvas';
import SimpleLineChart from '@/components/charts/SimpleLineChart.vue';
import { useIncidentStore } from '@/store/incident';
import IncidentListRenderer from '@/components/IncidentListRenderer.vue';
import DocumentQueryList from '@/views/DocumentQueryList.vue';
import DeadAnimalStatistics from '@/views/DeadAnimalStatistics.vue';
import { DateTime } from 'luxon';
import TriggerBarnIntervention from '@/components/incidentTriggers/TriggerBarnIntervention.vue';
import RegionChart from '@/components/charts/RegionChart.vue';
import LabelCountsLineChart from '@/components/charts/LabelCountsLineChart.vue'
import { canViewRegionsHelper } from '@/composables/permissionHelper.js'
import { useInstanceStore } from '@/store/backendInstances.js'
import BarnDocChartAnnotationProvider
  from '@/components/charts/IncidentChartAnnotationProviders/BarnDocChartAnnotationProvider.js'
import RegionSumChart from '@/components/charts/RegionSumChart.vue'

const instanceStore = useInstanceStore()
const canvasStore = useCanvasStore()

const timestamp = ref(DateTime.now().startOf('second'))
const newBarnInterventionDialog = ref(false)
const barnInterventionList = ref(null)

const baseCanvas = computed(() => {
  return canvasStore.getBaseCanvas()
})

const canSeeRegionSums = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.regionSums'])
})
const canSeeActivity = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.activity'])
})
const canSeeSpeed = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.speed'])
})

const timeSetup = ref({
  start: timestamp.value.toMillis() - 1000 * 60 * 60 * 24 * 4,
  end: timestamp.value.toMillis(),
  timeResolution: 1000 * 60 * 10,
})
provide('timeSetup', timeSetup)
provide('chainTimeSetup', ref(false))
onMounted(() => {
  canvasStore.keepBaseCanvasLoaded()
})
</script>

<template>
  <v-alert type="info" class="ma-2">
    On this new Barn Management Dashboard the Animal Count Charts should load faster.
    If you experience any issues, you can switch back to the old dashboard by clicking on the button below.
    <br>
    <v-btn
      class="mx-4 mt-4"
      variant="outlined"
      text="Go To Legacy Dashboard"
      @click="$router.push({name: 'Legacy Barn Management Dashboard'})"
    />
  </v-alert>
  <v-row no-gutters>
    <v-col :cols="12">
      <v-layout-card
        class="px-0"
        style="height: 60vh"
      >
        <CanvasRenderer
          v-if="baseCanvas"
          :disable-zoom-buttons="true"
          :override-canvas="baseCanvas"
          :model-value:base-rotation="270"
          :disable-element-picker="false"
          :disable-time-jumper="false"
          :disable-navigation="false"
          :disable-scrolling="true"
          :shrink-all-overlays-by-default="true"
          :timestamp="timestamp"
        />
      </v-layout-card>
    </v-col>
    <v-col
      v-if="canSeeActivity"
      :cols="12"
    >
      <SimpleLineChart
        :disable-control-row="false"
        :aggregate="true"
        measurement="activity"
        field="activity"
        :title="$t('charts.titles.activity')"
        :subtitle="$t('charts.subtitles.activityGlobal')"
        :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
      />
    </v-col>
    <v-col
      v-if="canSeeRegionSums"
      :cols="12"
    >
      <RegionSumChart
        :aggregate="false"
        :title="$t('charts.titles.labelCounts')"
        :subtitle="$t('charts.subtitles.labelCountsGlobal')"
        :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
        property="label"
      />
    </v-col>

    <v-col
      v-if="canSeeRegionSums"
      :cols="12"
    >
      <RegionSumChart
        :aggregate="false"
        property="stallbereich_vh"
        :title="$t('charts.titles.regions')"
        :subtitle="$t('charts.subtitles.regionsFrontBack')"
        :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
      />
    </v-col>
    <v-col
      v-if="canSeeRegionSums"
      :cols="12"
    >
      <RegionSumChart
        :aggregate="false"
        property="stalltechnik"
        :title="$t('charts.titles.regions')"
        :subtitle="$t('charts.subtitles.regionsBarnEquipment')"
        :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
      />
    </v-col>
    <v-col
      v-if="canSeeSpeed"
      :cols="12"
    >
      <SimpleLineChart
        :disable-control-row="false"
        :aggregate="true"
        measurement="speed"
        field="speed"
        :title="$t('charts.titles.activity')"
        :subtitle="$t('charts.subtitles.activityPerCamera')"
        :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
      />
    </v-col>
    <v-col :cols="12">
      <DeadAnimalStatistics
        :show-settings="false"
        :pre-defined-days="10"
      />
    </v-col>
    <v-col :cols="12">
      <v-layout-card>
        <v-row
          justify="space-between"
          align="center"
        >
          <v-col cols="auto">
            <v-card-title>{{ $t("barn_intervention_list.title") }}</v-card-title>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="ma-2 rounded-pill"
              variant="flat"
              color="primary"
            >
              {{ $t("barn_intervention_list.add") }}
              <v-dialog
                v-model="newBarnInterventionDialog"
                activator="parent"
              >
                <TriggerBarnIntervention
                  :shrinkable="false"
                  :show-cancel-button="true"
                  @cancel="newBarnInterventionDialog = false"
                  @saved="newBarnInterventionDialog = false; barnInterventionList?.reload()"
                />
              </v-dialog>
            </v-btn>
          </v-col>
        </v-row>

        <v-card-text>
          <DocumentQueryList
            ref="barnInterventionList"
            hide-sortings
            hide-filters
            hide-items-per-page
            :auto-load="true"
            :preset-items-per-page="5"
            query-type="incident"
            :query-function="useIncidentStore().queryIncidents"
            :enable-query-params="false"
            :preset-filters="[
              {key: 'type', operator: '==', value: 'barnIntervention', forced: true},
              {key: 'firstEventTimestamp', operator: '<=', value: timestamp.toMillis(), forced: true},
            ]"
            :preset-order="{sortBy: 'firstEventTimestamp', direction: 'desc'}"
          >
            <template #list="listProps">
              <IncidentListRenderer
                :incidents="listProps.documents"
                predefined-view-mode="table"
              />
            </template>
          </DocumentQueryList>
        </v-card-text>
      </v-layout-card>
    </v-col>
  </v-row>
</template>
