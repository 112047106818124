import GenericTimespanIncidentChartAnnotationProvider
  from '@/components/charts/IncidentChartAnnotationProviders/GenericTimespanIncidentChartAnnotationProvider.js'

import i18n from '@/plugins/i18n.js'
const { t } = i18n.global

class BarnDocChartAnnotationProvider extends GenericTimespanIncidentChartAnnotationProvider {
  constructor() {
    super('barnDocumentation', [
      { key: 'state', operator: '!=', value: 'hidden' },
      { key: 'messagePayload.specification.startTimestamp', operator: 'exists', value: false },
      { key: 'messagePayload.specification.timestamp', operator: 'exists', value: false },
    ], 'purple', 'rgba(140,0,255,0.5)')
  }
  getProviderTitle() {
    return 'Barn Documentation'
  }
  getLabelText(incident) {
    return t('incidents.barnDocumentation.specificationTypes.' + incident.messagePayload.specification.type)
  }
}

export default BarnDocChartAnnotationProvider
