<script setup>
import WYSIWYG from '@/components/WYSIWYG.vue'
import { inputRequiredRule } from '@/composables/inputRules'
import DateTimePicker from '@/components/DateTimePicker.vue'
import i18n from '../../plugins/i18n'
import { useToast } from 'vue-toastification'

const toast = useToast()
const { t } = i18n.global
import { computed, onMounted, ref, watch } from 'vue'

import { useIncidentStore } from '@/store/incident'

const incidentStore = useIncidentStore()

import CameraPicker from '@/components/formComponents/CameraPicker.vue'
import RegionSpecificationPicker from '@/components/formComponents/RegionSpecificationPicker.vue'
import { DateTime } from 'luxon'

const props = defineProps({
  incident: { type: Object, default: null },
  showCancelButton: { type: Boolean, default: false },
  shrinkable: { type: Boolean, default: false },
  preFilledFirstTimestamp: { type: Number, default: null },
  preFilledSecondTimestamp: { type: Number, default: null },
})
const emit = defineEmits(['saved', 'cancel'])
const classification = ref(null)
const specification = ref(null)
const isGlobal = ref(false)
const cameras = ref([])
const regionSpecifications = ref([])
const startTimestamp = ref(null)
const endTimestamp = ref(null)
const hasAlreadyEnded = ref(false)
const description = ref(null)
const schema = ref(null)
const loading = ref(false)
const expanded = ref(false)
const form = ref(null)

const availableClassifications = computed(() => {
  if (schema.value === null) return []
  const classifications = Object.keys(schema.value.barnDocumentation)
  return classifications.map(classification => ({
    value: classification,
    title: t('incidents.barnDocumentation.classifications.' + classification),
  }))
})

const availableSpecifications = computed(() => {
  if (schema.value === null || classification.value === null) return []
  const specifications = Object.keys(schema.value.barnDocumentation[classification.value])
  return specifications.map(specification => ({
    value: specification,
    title: t('incidents.barnDocumentation.specificationTypes.' + specification),
  }))
})

const useTimespan = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].time === 'timespan'
})

const useTimestamp = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].time === 'timestamp'
})

const allowGlobal = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes('global')
})

const allowRegions = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes('regionSpecification')
})

const allowCameraIds = computed(() => {
  if (schema.value === null || classification.value === null || specification.value === null) return false
  return schema.value.barnDocumentation[classification.value][specification.value].location.includes('cameraId')
})

watch(classification, () => {
  specification.value = null
})

watch(specification, () => {
  preFillTimestamps()
  description.value = null
  isGlobal.value = false
  cameras.value = []
  regionSpecifications.value = []
})

watch(isGlobal, () => {
  if (isGlobal.value) {
    cameras.value = []
    regionSpecifications.value = []
  }
})

function resetFields() {
  classification.value = null
  specification.value = null
  preFillTimestamps()
  description.value = null
  isGlobal.value = false
  cameras.value = []
  regionSpecifications.value = []
}

async function saveDocumentation() {
  let incidentId
  let incidentPayload
  form.value.validate()
  if(!form.value.isValid) {
    toast.error(t('toast.fix_errors_above'))
    return
  }
  if(!props.incident) {
    if (!isGlobal.value && !cameras.value.length && !regionSpecifications.value.length) {
      toast.error(t('incidents.barnDocumentation.no_location_selected'))
      return;
    }
    if (!startTimestamp.value || (hasAlreadyEnded.value && !endTimestamp.value)) {
      toast.error(t('incidents.barnDocumentation.no_time_selected'))
      return;
    }
    if(hasAlreadyEnded.value && endTimestamp.value <= startTimestamp.value) {
      toast.error(t('incidents.barnDocumentation.end_must_be_after_start'))
      return;
    }




    incidentPayload = {
      type: 'barnDocumentation',
      trigger: useTimestamp.value ? 'observed' : 'started',
      eventTimestamp: startTimestamp.value.toMillis(),
      identifier: {},
      payload: {
        classification: classification.value,
        specification: {
          type: specification.value,
          location: isGlobal.value ? 'global' : {
            cameraId: allowCameraIds.value && cameras.value && cameras.value.length > 0 ? cameras.value : undefined,
            regionSpecification: allowRegions.value && regionSpecifications.value && regionSpecifications.value.length > 0 ? regionSpecifications.value : undefined,
          },
        },
        description: description.value ? description.value : undefined,
      },
    }
    const incidentData = await incidentStore.triggerGenericIncident(incidentPayload)
    if (!incidentData) {
      toast.error(t('toast.generic_could_not_save'))
      return;
    }
    incidentId = incidentData.incident.incidentId
    if(hasAlreadyEnded.value) {
      const successful = await incidentStore.triggerSpecificIncident(incidentId, {
        trigger: 'ended',
        eventTimestamp: endTimestamp.value.toMillis(),
      })
      if (!successful) {
        toast.error(t('toast.generic_could_not_save'))
        return;
      }
    }} else {
    if (!endTimestamp.value) {
      toast.error(t('incidents.barnDocumentation.no_time_selected'))
      return;
    }
    if(endTimestamp.value.toMillis() <= props.incident.firstEventTimestamp) {
      toast.error(t('incidents.barnDocumentation.end_must_be_after_start'))
      return;
    }
    const successful = await incidentStore.triggerSpecificIncident(props.incident.incidentId, {
      trigger: 'ended',
      eventTimestamp: endTimestamp.value.toMillis(),
    })
    if (!successful) {
      toast.error(t('toast.generic_could_not_save'))
      return;
    }
    incidentId = props.incident.incidentId
  }
  toast.success(t('toast.generic_saved'))
  emit('saved',incidentId)

}

function preFillTimestamps() {
  startTimestamp.value = null
  endTimestamp.value = null
  if(props.preFilledFirstTimestamp && !props.preFilledSecondTimestamp) {
    startTimestamp.value = DateTime.fromMillis(props.preFilledFirstTimestamp)
  }
  if(props.preFilledFirstTimestamp && props.preFilledSecondTimestamp) {
    startTimestamp.value = DateTime.fromMillis(Math.min(props.preFilledFirstTimestamp, props.preFilledSecondTimestamp))
    endTimestamp.value = DateTime.fromMillis(Math.max(props.preFilledFirstTimestamp, props.preFilledSecondTimestamp))
    hasAlreadyEnded.value = true
  }
}

onMounted(async () => {
  preFillTimestamps()
  schema.value = await incidentStore.getSimplifiedIncidentSchema('barnDocumentation')
})
</script>

<template>
  <v-layout-card
    :loading="loading"
    :disabled="loading"
  >
    <v-row
      :dense="true"
      class="justify-space-between"
    >
      <v-col cols="auto">
        <v-card-title>
          {{ incident ? $t('incidents.barnDocumentation.update_title') : $t('incidents.barnDocumentation.new_title') }}
        </v-card-title>
      </v-col>
      <v-col
        v-if="shrinkable"
        cols="auto"
      >
        <v-btn
          class="ma-2"
          :icon="expanded? 'mdi-chevron-up': 'mdi-chevron-down'"
          variant="tonal"
          color="primary"
          @click="expanded = !expanded"
        />
      </v-col>
    </v-row>

    <v-expand-transition mode="in-out">
      <v-card-text v-if="!shrinkable || expanded">
        <v-form
          ref="form"
        >
          <div v-if="!incident">
            <h3 class="mt-2 mb-6">
              {{ $t('incidents.barnDocumentation.classification') }}
            </h3>
            <v-autocomplete
              v-model="classification"
              :items="availableClassifications"
              variant="outlined"
              :label="$t('incidents.barnDocumentation.classification')"
              :rules="[inputRequiredRule]"
            />
            <v-autocomplete
              v-model="specification"
              :items="availableSpecifications"
              variant="outlined"
              :label="$t('incidents.barnDocumentation.specification')"
              :disabled="classification === null"
              :rules="[inputRequiredRule]"
            />

            <div v-if="allowGlobal || allowCameraIds || allowRegions">
              <v-divider />
              <h3
                class="my-2"
              >
                {{ $t('incidents.barnDocumentation.location') }}
              </h3>
              <v-checkbox
                v-if="allowGlobal"
                v-model="isGlobal"
                :label="$t('incidents.barnDocumentation.is_global')"
              />

              <CameraPicker
                v-if="allowCameraIds"
                v-model="cameras"
                :disabled="isGlobal"
                :label="$t('general_interface.camera_picker.label')"
              />

              <RegionSpecificationPicker
                v-if="allowRegions"
                v-model="regionSpecifications"
                :disabled="isGlobal"
                :label="$t('general_interface.region_specification_picker.label')"
              />
            </div>

            <div>
              <v-divider />
              <h3 class="my-2">
                {{ $t('incidents.barnDocumentation.time') }}
              </h3>
              <DateTimePicker
                fullwidth
                v-model="startTimestamp"
                :label="useTimestamp
                  ? $t('general_interface.date_time_picker.label')
                  : $t('general_interface.date_time_picker.start')"
              />
              <v-row>
                <v-col
                  v-if="hasAlreadyEnded"
                >
                  <DateTimePicker
                    fullwidth
                    v-model="endTimestamp"
                    :disabled="useTimestamp"
                    :label="$t('general_interface.date_time_picker.end')"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="hasAlreadyEnded"
                    label="Provide End Timestamp Now"
                    :disabled="useTimestamp"
                    class="my-5"
                  />
                </v-col>
              </v-row>
            </div>

            <div>
              <v-divider />
              <h3 class="my-2">
                {{ $t('incidents.barnDocumentation.additional') }}
              </h3>
              <WYSIWYG
                v-model="description"
                :label="$t('incidents.barnDocumentation.titles.description')"
              />
            </div>
          </div>
          <div v-if="incident && incident.state === 'created'">
            <DateTimePicker
              v-model="endTimestamp"
              :label="$t('general_interface.date_time_picker.end')"
            />
          </div>
        </v-form>
      </v-card-text>
    </v-expand-transition>

    <v-fade-transition mode="in-out">
      <v-card-actions
        v-if="!shrinkable || expanded"
        class="justify-end"
      >
        <v-btn
          v-if="!incident"
          color="error"
          variant="outlined"
          @click="resetFields"
        >
          {{ $t("general_interface.buttons.reset") }}
        </v-btn>

        <v-btn
          v-if="showCancelButton"
          variant="outlined"
          color="error"
          @click="$emit('cancel')"
        >
          {{ $t("general_interface.buttons.cancel") }}
        </v-btn>

        <v-btn
          color="primary"
          variant="flat"
          @click="saveDocumentation"
        >
          {{ $t("general_interface.buttons.save") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
    <v-fade-transition mode="in-out">
      <v-card-actions
        v-if="shrinkable && !expanded"
        class="justify-center"
      >
        <v-btn
          variant="flat"
          color="success"
          class="rounded-pill"
          @click="expanded = true"
        >
          {{ $t("barn_documentation_list.add") }}
        </v-btn>
      </v-card-actions>
    </v-fade-transition>
  </v-layout-card>
</template>

<style scoped>

</style>
