<script setup>

import { computed, ref } from 'vue';
import { useInstanceStore } from '@/store/backendInstances';
import AccountPicker from '@/components/AccountPicker.vue';
import { useIncidentStore } from '@/store/incident';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
import { useToast } from 'vue-toastification';

const toast = useToast();
const instanceStore = useInstanceStore()
const incidentStore = useIncidentStore()
const props = defineProps({ incident: { type: Object, required: true } })
const emit = defineEmits(['reload'])


const showAssignMenu = ref(false)
const loading = ref(false)

const isUnassigned = computed(() => {
  return !Object.keys(props.incident).includes('assignedTo')
})

const currentAssigneeName = computed(() => {
  if (isUnassigned.value) return null
  return props.incident.assignedTo.displayName
})

const canAssignIncident = computed(() => {
  return instanceStore.hasAnyPermission(['incidents.manage', 'incidents.manage.advanced', `incidents.${props.incident.type}.manage`])
})

const isAssignedToCurrentAccount = computed(() => {
  return !isUnassigned.value && instanceStore.getCurrentAccountId === props.incident.assignedTo.entityId;
})

const canSeeAssignablePeople = computed(() => {
  return instanceStore.hasPermission('authentication.list_users')
})

async function unAssignIncident() {
  loading.value = true
  const result = await incidentStore.unAssignIncident(props.incident.incidentId)
  loading.value = false
  if (result) {
    toast.success(t('assign_incidents.unassigned_success'))
  } else {
    toast.error(t('assign_incidents.unassigned_success'))
  }
  emit('reload')
}

async function assignToAccount(assignee) {
  loading.value = true
  const result = await incidentStore.assignIncidentToAccount(props.incident.incidentId, assignee)
  loading.value = false
  if (result) {
    toast.success(t('assign_incidents.assigned_success'))
  } else {
    toast.error(t('assign_incidents.assigned_error'))
  }
  emit('reload')
}

async function assignToCurrentAccount() {
  return assignToAccount(instanceStore.getCurrentAccountId)
}

</script>

<template>
  <v-btn
    :color="!isUnassigned ? 'primary' : ''"
    class="my-2 mx-1 rounded-pill"
    :disabled="!canAssignIncident"
    size="small"
  >
    {{ isUnassigned ? $t("assign_incidents.unassigned") : currentAssigneeName }}

    <v-menu
      v-model="showAssignMenu"
      activator="parent"
      :close-on-content-click="false"
      min-width="200px"
      offset="10px"
    >
      <v-sheet elevation="10">
        <v-row class="justify-center">
          <v-col
            v-if="!isUnassigned"
            cols="auto"
          >
            <v-btn
              size="small"
              variant="outlined"
              color="error"
              class="rounded-pill mt-2 mx-2"
              @click="unAssignIncident"
            >
              {{ $t("assign_incidents.unassign") }}
            </v-btn>
          </v-col>
          <v-col
            v-if="!isAssignedToCurrentAccount"
            cols="auto"
          >
            <v-btn
              color="primary"
              size="small"
              class="rounded-pill mt-2 mx-2"
              @click="assignToCurrentAccount"
            >
              {{ $t("assign_incidents.assign_to_me") }}
            </v-btn>
          </v-col>
          <v-col
            v-if="canSeeAssignablePeople"
            :cols="12"
          >
            <AccountPicker
              :disabled="loading"
              @update:model-value="assignToAccount($event)"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>
  </v-btn>
</template>
