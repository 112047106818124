<script setup>
import { computed, onMounted, ref } from 'vue'
import { loadRegionMeasurement } from '@/composables/charts'
import { useMeasurementStore } from '@/store/measurements'
import { useRegionStore } from '@/store/regions'
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue'

const regionStore = useRegionStore()

const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregateLabels: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    regionType: { type: String, default: '' },
    specifiedLabels: { type: Array, default: null },
    defaultCollapsed: { type: Boolean, default: false },
    fixedRegionSpecifications: { type: Array, default: null },
  },
)
const options = ref({})

const labels = ref([])

async function dataLoader(cameraIds, timeSetup, abortSignal) {
  return await loadRegionMeasurement({
    timeResolution: timeSetup.timeResolution,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregateCameras: props.aggregateCameras,
    aggregateLabels: props.aggregateLabels,
    chartType: 'line',
    labels: labels.value,
    regionType: props.regionType,
    regionSpecifications: regionSpecifications.value,
  }, abortSignal)
}

const regionTypes = computed(() => regionStore.getRegionTypes())
const regionSpecifications = computed(() => {
  if (props.fixedRegionSpecifications) return props.fixedRegionSpecifications
  const specifications = regionStore.getRegionSpecifications()
  if (!specifications) return []
  return Object.values(specifications)
    .filter(spec => spec.regionType === props.regionType)
    .map(spec => spec.regionSpecificationId)
})

const cannotRender = computed(() => {

  if (!props.aggregateLabels && (!labels.value || !labels.value.length)) return true
  if (!regionTypes.value) return true
  return !Object.keys(regionTypes.value).includes(props.regionType)
})


onMounted(async () => {
  if (props.specifiedLabels) labels.value = props.specifiedLabels
  else labels.value = await useMeasurementStore().loadUsedLabels()
  await regionStore.ensureRegionsAreLoaded()
})


</script>

<template>
  <CameraBaseChart
    v-if="!cannotRender && regionSpecifications && labels"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :default-collapsed="props.defaultCollapsed"
    :options="options"
    :title="props.title"
    :subtitle="props.subtitle"
  />
</template>

<style scoped>

</style>
