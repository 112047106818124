import { reactive } from 'vue'
import { randomString } from '@/composables/helperFunctions.js'

/**
 * Incident Chart Annotation Provider. Generic Base Class, should be subclassed for specific use cases.
 * This Base Class provides the basic setup for loading Incident(Event) Data and creating annotations that will
 * be displayed on the chart.
 */
class IncidentChartAnnotationProvider {
  /**
   * Constructor
   */
  constructor() {
    this.initialized = false
    this.chartRef = null
    this.annotations = reactive([])
    this.providerId = randomString(20)
  }

  /**
   * Initializes this Provider. Has to be called before calling loadData.
   * @param chartRef The Reference to the HighChart Chart Instance
   * @param highlightAnnotationsForAnnotationGroup Callback to highlight Annotations for a specific Annotation Group
   * @param resetAnnotationHighlight Callback to reset the Highlighting of Annotations
   * @param showAnnotationDialog Callback to show a Dialog for a specific Annotation
   * @param reloadAnnotations Callback to reload Annotations
   */
  initialize(chartRef,
    highlightAnnotationsForAnnotationGroup,
    resetAnnotationHighlight,
    showAnnotationDialog,
    reloadAnnotations) {
    if(this.initialized) return
    this.chartRef = chartRef
    this.highlightAnnotationsForAnnotationGroup = highlightAnnotationsForAnnotationGroup
    this.resetAnnotationHighlight = resetAnnotationHighlight
    this.showAnnotationDialog = showAnnotationDialog
    this.reloadAllAnnotations = reloadAnnotations
    this.initialized = true
  }

  /**
   * Wrapper around #loadData. Do not override this method, override #loadData instead. This Wrapper ensures
   * that the provider is initialized before loading data.
   * @param {Number} startTimestamp
   * @param {Number} endTimestamp
   * @param abortSignal
   * @returns {Promise<void>}
   */
  async load(startTimestamp, endTimestamp, abortSignal) {
    if (!this.initialized) {
      throw new Error('Provider not initialized. Call initialize before loading data.')
    }
    this.annotations.length = 0
    await this.loadData(startTimestamp, endTimestamp, abortSignal)
    this.buildAnnotations(startTimestamp, endTimestamp)
  }

  /**
   * Returns the Human Readable Title of the Provider. Override this method in subclass.
   */
  getProviderTitle() {
    throw new Error('Not Implemented, override this method in subclass')
  }

  /**
   * Loads the Data for the Provider. Override this method in subclass.
   * @param startTimestamp
   * @param endTimestamp
   * @param abortSignal
   * @returns {Promise<void>}
   */
  // eslint-disable-next-line no-unused-vars
  async loadData(startTimestamp, endTimestamp, abortSignal) {
    throw new Error('Not Implemented, override this method in subclass')
  }

  /**
   * Builds the Annotations for the Provider. Override this method in subclass.
   * @param startTimestamp
   * @param endTimestamp
   */
  // eslint-disable-next-line no-unused-vars
  buildAnnotations(startTimestamp, endTimestamp){
    throw new Error('Not Implemented, override this method in subclass')
  }

  /**
   * Highlights all Annotations for a specific Annotation Group Id.
   * Potentially makes all other Annotations invisible (or at least less visible)
   * @param annotationGroupId
   */
  highlightAnnotations(annotationGroupId) {
    this.annotations.forEach(annotation => {
      annotation.visible = annotationGroupId === annotation.annotationGroupId;
    })
  }

  /**
   * Get the (probably) unique provider id.
   * @returns {String}
   */
  getProviderId() {
    return this.providerId
  }

  /**
   * Resets the Highlighting of Annotations. Makes all Annotations visible again.
   */
  resetHighlight() {
    this.annotations.forEach(annotation => {
      annotation.visible = true;
    })
  }

  /**
   * Returns the Annotations for this Provider.
   * @returns List of Annotations
   */
  getAnnotations() {
    return this.annotations
  }



  /**
   *UTILITY FUNCTIONS FOR LABEL POSITIONING
   */

  /**
   * Finds a point on the specific series that has the x value and calculates the slope of the series at that point.
   * @param x The X Value
   * @param series
   * @param windowSizeForSlopeCalculation
   * @returns {{slope: number, point: {x, y: *}}|{slope: number, point: *}}
   *          If the Slope is positive, the label should be aligned to the right, if negative to the left.
   *          A mock point is created at the specific X position to calculate the slope. and align the label
   */
  findMockPointAndSlopeForXValue(x, series, windowSizeForSlopeCalculation = 5) {
    let data = series.data.sort((a, b) => a.x - b.x)
    let point = data.find(point => point.x === x)
    if (point) {
      let index = data.indexOf(point)
      let startIndex = Math.max(0, index - windowSizeForSlopeCalculation)
      let endIndex = Math.min(data.length - 1, index + windowSizeForSlopeCalculation)
      let slope = (data[endIndex].y - data[startIndex].y) / (data[endIndex].x - data[startIndex].x)
      return { point, slope }
    } else {
      let closestPointBefore = data.filter(point => point.x <= x).sort((a, b) => b.x - a.x)[0]
      let closestPointAfter = data.filter(point => point.x >= x).sort((a, b) => a.x - b.x)[0]
      if (closestPointBefore && closestPointAfter) {
        let slope = (closestPointAfter.y - closestPointBefore.y) / (closestPointAfter.x - closestPointBefore.x)
        let y = (slope * (x - closestPointBefore.x)) + closestPointBefore.y
        return { point: { x, y }, slope }
      } else {
        return { point: { x, y: 0 }, slope: 0 }
      }
    }
  }

  /**
   * Takes a Value on the X axis, and returns the best rendering position for a label at that X Value.
   * @param x {number} The X Value
   * @param connected {boolean} If true, the label will be connected to the series.
   * If false, the label will be positioned at the top or bottom of the chart
   * @returns {{point: {yAxis: number, xAxis: number, x: number, y: number}}}
   */
  getRenderPositionForLabelAtXValue(x, connected=true) {
    if (!this.initialized) {throw new Error('Provider not initialized. Call initialize before loading data.')}
    if(!this.chartRef) {throw new Error('Chart Reference not set.')}

    const seriesArray = this.chartRef.value.chart.series

    if (seriesArray.length === 0) {
      return { point: { xAxis: 0, yAxis: 0, x: x, y: 0 } }
    }
    const extremes = this.chartRef.value.chart.yAxis[0].getExtremes()
    if(connected) {
      if (seriesArray.length === 1) {
        const { point, slope } = this.findMockPointAndSlopeForXValue(x, seriesArray[0])
        return {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: point.x,
            y: point.y,
          },
          align: slope > 0 ? 'right' : 'left',
        }
      }
      if (seriesArray.length > 1) {
        return {
          point: {
            xAxis: 0,
            yAxis: 0,
            x: x,
            y: extremes.dataMax,
          },
          align: 'center',
        }
      }
    } else {
      if(extremes.max - extremes.dataMax > extremes.dataMin - extremes.min) {
        return { point: { xAxis: 0, yAxis: 0, x: x, y: extremes.max },  verticalAlign: 'top' }
      }
      return { point: { xAxis: 0, yAxis: 0, x: x, y: extremes.min }, verticalAlign: 'bottom' }
    }
  }
}

export default IncidentChartAnnotationProvider
