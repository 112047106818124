<script setup>
import { humanReadableTimestamp } from '@/composables/datetime.js'
import { computed } from 'vue'
import { useInstanceStore } from '@/store/backendInstances.js'
import { useRouter } from 'vue-router'
const router = useRouter()

const props = defineProps({
  clickedDataPoint: { type: Object, default: () => ({}) },
})



const canJumpToFrameSeek = computed(() => {
  if (!props.clickedDataPoint) return false
  return useInstanceStore().hasAnyPermission([
    'recordings.view.frame',
    'streaming.recording.view',
    'recordings.view',
  ])
})

const canJumpToCanvas = computed(() => {
  if (!props.clickedDataPoint) return false
  return useInstanceStore().hasAnyPermission(['cameraCanvases.view', 'cameraCanvases.view.basic'])
})
const canJumpToRecordingStream = computed(() => {
  if (!props.clickedDataPoint?.series?.options?.dimensionKeys || !props.clickedDataPoint.series.options.dimensionKeys.includes('camera'))
    return false
  return useInstanceStore().hasAnyPermission(['streaming.recording.view'])
})

const canAnnotateBarnDoc = computed(() => {
  return useInstanceStore().hasAnyPermission([
    'incidents.barnDocumentation.manage',
    'incidents.manage',
    'incidents.manage.advanced'])
})

function jumpToFrameSeek() {
  const query = { timestamp: props.clickedDataPoint.x }
  if (props.clickedDataPoint.series.options.dimensionKeys) {
    const index = props.clickedDataPoint.series.options.dimensionKeys.findIndex(key => key === 'camera')
    if (index !== -1) {
      query.cameraId = props.clickedDataPoint.series.options.datasetSelector[index]
    }
  }
  return router.push({ name: 'Frame Seek', query: query })
}

function jumpToCanvas() {
  return router.push({ name: 'CameraCanvas', query: { timestamp: props.clickedDataPoint.x } })
}
function jumpToRecordingStream() {
  const index = props.clickedDataPoint.series.options.dimensionKeys.findIndex(key => key === 'camera')
  const cameraId = props.clickedDataPoint.series.options.datasetSelector[index]
  return router.push({
    name: 'RecordingStream',
    params: { selectorMode: 'camera', selectorId: cameraId, timestamp: props.clickedDataPoint.x },
  })
}

function startBarnDocumentationIncident() {
  firstSelectedTimestamp.value = props.clickedDataPoint.x
  showDataPointClickDialog.value = false
}

function finishBarnDocumentationIncident() {
  emit('finishBarnDocumentationIncident', firstSelectedTimestamp.value, props.clickedDataPoint.x)
  showDataPointClickDialog.value = false
}

const showDataPointClickDialog = defineModel('showDialog',{ type: Boolean, default: false })
const firstSelectedTimestamp = defineModel('firstSelectedTimestamp', { type: Number, default: null })
const emit = defineEmits(['finishBarnDocumentationIncident'])
</script>

<template>
  <v-dialog
    v-model="showDataPointClickDialog"
    width="auto"
    max-width="600"
    min-width="300"
  >
    <v-layout-card>
      <v-card-title>{{ humanReadableTimestamp(clickedDataPoint.x) }}</v-card-title>
      <v-card-subtitle>{{ clickedDataPoint.series.name }}</v-card-subtitle>
      <v-card-text>
        <v-btn
          v-if="canJumpToCanvas"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-artboard"
          @click="jumpToCanvas"
        >
          {{ $t('charts.go_to_canvas') }}
        </v-btn>
        <v-btn
          v-if="canJumpToFrameSeek"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-image-frame"
          @click="jumpToFrameSeek"
        >
          {{ $t('charts.go_to_frame_seek') }}
        </v-btn>
        <v-btn
          v-if="canJumpToRecordingStream"
          class="mt-4"
          block
          variant="outlined"
          color="primary"
          prepend-icon="mdi-video-outline"
          @click="jumpToRecordingStream"
        >
          {{ $t('charts.go_to_recording_stream') }}
        </v-btn>
        <v-btn
          v-if="canAnnotateBarnDoc && !firstSelectedTimestamp"
          class="mt-4"
          variant="outlined"
          color="primary"
          block
          @click="startBarnDocumentationIncident"
        >
          Start Annotation From Here
        </v-btn>
        <v-btn
          v-if="canAnnotateBarnDoc && firstSelectedTimestamp"
          class="mt-4"
          variant="outlined"
          color="primary"
          block
          @click="finishBarnDocumentationIncident"
        >
          Finish Annotation with This Timestamp
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          variant="outlined"
          @click="showDataPointClickDialog = false"
        >
          {{ $t('general_interface.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-layout-card>
  </v-dialog>
</template>

<style scoped>

</style>
