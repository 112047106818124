<script setup>
import NightvisionTimelineChart from '@/components/charts/NightvisionTimelineChart.vue'
import SimpleLineChart from '@/components/charts/SimpleLineChart.vue'
import RegionChart from '@/components/charts/RegionChart.vue'
import { computed, onMounted, ref, provide } from 'vue'
import ChartTimeSetupPicker from '@/components/charts/ChartTimeSetupPicker.vue'
import { useInstanceStore } from '@/store/backendInstances.js'
import GridScoreDerivativeChart from '@/components/charts/GridScoreDerivativeChart.vue'
import { useRoute, useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import BarnDocChartAnnotationProvider
  from '@/components/charts/IncidentChartAnnotationProviders/BarnDocChartAnnotationProvider.js'
import RegionSumChart from '@/components/charts/RegionSumChart.vue'

const instanceStore = useInstanceStore()
const router = useRouter()
const route = useRoute()

const chainedTimeSetup = ref(null)
const chainTimeSetup = ref(true)
const startTime = ref(null)
const endTime = ref(null)

const canSeeDistributionScores = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.distribution'])
})
const canSeeRegions = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.regions'])
})
const canSeeActivity = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.activity'])
})
const canSeeSpeed = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.speed'])
})
const canSeeImageQuality = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.imageQuality'])
})

const canSeeRegionSums = computed(() => {
  return instanceStore.hasAnyPermission(['data.view', 'data.view.regionSums'])
})


function updateChainedTimeSetup(timeSetup) {
  if (chainTimeSetup.value) chainedTimeSetup.value = timeSetup
  router.replace({
    query: {
      start: timeSetup.start,
      end: timeSetup.end,
    },
  })
}

provide('timeSetup', chainedTimeSetup)
provide('chainTimeSetup', chainTimeSetup)

const setStartTime = () => {
  if (route.query.start || !isNaN(parseInt(route.query.start))) {
    return startTime.value = DateTime.fromMillis(parseInt(route.query.start))
  }
  return startTime.value = DateTime.now().minus({ days: 4 }).startOf('second')
}

const setEndTime = () => {
  if (route.query.end || !isNaN(parseInt(route.query.end))) {
    return endTime.value = DateTime.fromMillis(parseInt(route.query.end))
  }
  return endTime.value = DateTime.now().startOf('second')
}
onMounted(() => {
  setStartTime()
  setEndTime()
})
</script>

<template>
  <v-container
    :fluid="true"
    class="ma-0 pa-0"
  >
    <v-layout-card class="mx-0 px-0">
      <v-card-title>
        {{ $t('measurements.titles.barn_measurements') }}
      </v-card-title>
      <v-card-text>
        <v-alert type="info">
          On this new Barn Measurements Dashboard the Animal Count Charts should load faster.
          If you experience any issues, you can switch back to the old dashboard by clicking on the button below.
          <br>
          <v-btn
            class="mx-4 mt-4"
            variant="outlined"
            text="Go To Legacy Dashboard"
            @click="router.push({name: 'Legacy Measurements Dashboard'})"
          />
        </v-alert>

        <v-row
          justify="center"
        >
          <v-col :cols="12">
            <v-row class="justify-end">
              <v-col cols="auto">
                <v-switch
                  v-model="chainTimeSetup"
                  :label="$t('charts.chain_time_setup')"
                />
              </v-col>
              <v-col
                cols="auto"
              >
                <ChartTimeSetupPicker
                  v-if="chainTimeSetup && startTime && endTime"
                  :model-value="chainedTimeSetup"
                  :hide-time-resolution="true"
                  :start-time="startTime"
                  :end-time="endTime"
                  @update:model-value="updateChainedTimeSetup($event)"
                />
              </v-col>
            </v-row>
          </v-col>
          <div
            v-if="chainedTimeSetup"
            class="w-100"
          >
            <v-col
              v-if="canSeeActivity"
              :cols="12"
            >
              <SimpleLineChart
                measurement="activity"
                :aggregate="true"
                :subtitle="$t('charts.subtitles.activityGlobal')"
                field="activity"
                :title="$t('charts.titles.activity')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeSpeed"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="true"
                measurement="speed"
                field="speed"
                :subtitle="$t('charts.subtitles.speedGlobal')"
                :title="$t('charts.titles.speed')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <SimpleLineChart
                :for-all-cameras="true"
                :aggregate="true"
                measurement="buckets"
                field="gridScore"
                :subtitle="$t('charts.subtitles.gridScore')"
                :title="$t('charts.titles.globalGridScore')"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="distribution"
                field="gridScore"
                :subtitle="$t('charts.subtitles.gridScore')"
                :title="$t('charts.titles.gridScore')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <GridScoreDerivativeChart
                :title="$t('charts.titles.gridScoreSpread')"
                :subtitle="$t('charts.subtitles.gridScoreSpread')"
                :aggregate-cameras="true"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeDistributionScores"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="distribution"
                field="spatialDisparity"
                :subtitle="$t('charts.subtitles.spatialDisparity')"
                :title="$t('charts.titles.spatialDisparity')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeRegionSums"
              :cols="12"
            >
              <RegionSumChart
                :aggregate="false"
                property="stalltechnik"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsBarnEquipment')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeImageQuality"
              :cols="12"
            >
              <NightvisionTimelineChart
                :default-collapsed="true"
                :aggregate="false"
                :title="$t('charts.titles.nightvision_range_bar')"
              />
            </v-col>

            <v-col
              v-if="canSeeActivity"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="activity"
                :subtitle="$t('charts.subtitles.activityPerCamera')"
                field="activity"
                :title="$t('charts.titles.activity')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>


            <v-col
              v-if="canSeeSpeed"
              :cols="12"
            >
              <SimpleLineChart
                :aggregate="false"
                measurement="speed"
                field="speed"
                :subtitle="$t('charts.subtitles.speedPerCamera')"
                :title="$t('charts.titles.speed')"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>


            <v-col
              v-if="canSeeRegionSums"
              :cols="12"
            >
              <RegionSumChart
                :aggregate="false"
                property="stallbereich_vh"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsFrontBack')"
                :height="600"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeRegionSums"
              :cols="12"
            >
              <RegionSumChart
                :aggregate="false"
                property="stallbereich_lr"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsLeftRight')"
                :height="600"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>


            <v-col
              v-if="canSeeRegionSums"
              :cols="12"
            >
              <RegionSumChart
                :aggregate="false"
                property="label"
                :title="$t('charts.titles.labelCounts')"
                :subtitle="$t('charts.subtitles.labelCountsGlobal')"
                :height="400"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeRegionSums"
              :cols="12"
            >
              <RegionSumChart
                :aggregate="false"
                property="camera"
                :title="$t('charts.titles.labelCounts')"
                :subtitle="$t('charts.subtitles.labelCountsPerCamera')"
                :height="600"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>

            <v-col
              v-if="canSeeRegions"
              :cols="12"
            >
              <RegionChart
                :aggregate-labels="true"
                :region-type="'pen'"
                :specified-labels="[
                  'piglet',
                  'piglet_lying',
                  'piglet_standing',
                  'piglet_sitting',
                ]"
                :title="$t('charts.titles.regions')"
                :subtitle="$t('charts.subtitles.regionsPen')"
                :height="600"
                :incident-annotation-providers="[{provider: BarnDocChartAnnotationProvider, params: []}]"
              />
            </v-col>
          </div>
        </v-row>
      </v-card-text>
    </v-layout-card>
  </v-container>
</template>

<style scoped></style>
