<script setup>

import { onMounted, ref } from 'vue'
import { loadLabelCountsMeasurement } from '@/composables/charts'
import { useMeasurementStore } from '@/store/measurements'
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue'

const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregateCameras: { type: Boolean, default: false },
    aggregateLabels: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    defaultCollapsed: { type: Boolean, default: false },
    incidentAnnotationProviders: { type: Array, default: () => [] },
  },
)

const options = ref({})


let labels = []
const readyToRender = ref(false)
async function dataLoader(cameraIds, timeSetup, abortSignal) {
  return await loadLabelCountsMeasurement({
    cameraIds: cameraIds,
    timeResolution: timeSetup.timeResolution,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregateCameras: props.aggregateCameras,
    aggregateLabels: props.aggregateLabels,
    labels: labels,
  }, abortSignal)
}


onMounted(async () => {
  labels = await useMeasurementStore().loadUsedLabels();
  readyToRender.value = true
})


</script>

<template>
  <CameraBaseChart
    v-if="readyToRender"
    :default-collapsed="defaultCollapsed"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :title="title"
    :incident-annotation-providers="incidentAnnotationProviders"
    :subtitle="subtitle"
  />
</template>

<style scoped>

</style>
