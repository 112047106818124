import { defineStore } from 'pinia'
import { useInstanceStore } from '@/store/backendInstances'
import RegionsSyncable from '@/syncing/syncables/RegionsSyncable.js'
import SyncableQueue from '@/syncing/SyncableQueue.js'
import backendHandler from '@/BackendAPI.js'

export const useRegionStore = defineStore('regions', {
  state: () => ({
    regions: {},
  }),
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
  getters: {
    getRegionTypes() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionTypes : null
    },
    getPenRegionSpecifications() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? Object.values(this.regions[globalUniqueIdentifier].regionSpecifications).filter(spec => spec.regionType === 'pen') : null
    },
    getRegionSpecifications() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionSpecifications : null
    },
    getRegionSpecificationsForRegionType() {
      return (regionType, globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => {
        const specs = this.getRegionSpecifications(globalUniqueIdentifier)
        if (!specs) return []
        return Object.values(specs).filter(spec => spec.regionType === regionType)
      }
    },
    getRegionMappings() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionMappings : null
    },
    getRegionMappingsForCameras() {
      return (cameraIds, globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => {
        const mappings = this.getRegionMappings(globalUniqueIdentifier)
        if (!mappings) return {}
        return Object.fromEntries(Object.entries(mappings).filter(([, value]) => cameraIds.includes(value.cameraId)))
      }
    },
  },
  actions: {
    async loadRegionSetup(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      const regionTypeResponse = await backendHandler.get(globalUniqueIdentifier, { path: '/api/regions/types', priority: 2 })
      const regionSpecificationsResponse = await backendHandler.get(globalUniqueIdentifier, { path: '/api/regions/specifications', priority: 2 })
      const regionMappingsResponse = await backendHandler.get(globalUniqueIdentifier, { path: '/api/regions/mappings', priority: 2 })
      const regions = {}
      if (regionTypeResponse.ok) regions.regionTypes = regionTypeResponse.data.result
      if (regionSpecificationsResponse.ok) regions.regionSpecifications = regionSpecificationsResponse.data.result
      if (regionMappingsResponse.ok) regions.regionMappings = regionMappingsResponse.data.result
      this.regions[globalUniqueIdentifier] = regions
      return regions
    },

    async ensureRegionsAreLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      const syncable = this.keepRegionsLoaded(globalUniqueIdentifier)
      await SyncableQueue.ensureSyncable(globalUniqueIdentifier, syncable.handle)
    },
    keepRegionsLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      if (SyncableQueue.doesSyncableExist(globalUniqueIdentifier, RegionsSyncable.getSyncableHandle())) return SyncableQueue.jobs[globalUniqueIdentifier][RegionsSyncable.getSyncableHandle()]
      const syncable = new RegionsSyncable(globalUniqueIdentifier)
      SyncableQueue.addSyncable(syncable)
      return syncable
    },

    async createRegionMapping(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, cameraId, regionType, regions) {
      const response = await backendHandler.post(globalUniqueIdentifier, {
        path: '/api/regions/mappings',
        data: { value: { cameraId, regionType, regions } },
      })
      return response.ok
    },
    async updateRegionMapping(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionMappingId, regions) {
      const response = await backendHandler.patch(globalUniqueIdentifier, {
        path: '/api/regions/mappings/' + regionMappingId,
        data: { value: { regions } },
      })
      return response.ok
    },
    async deleteRegionSpecification(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionSpecificationId) {
      const response = await backendHandler.delete(globalUniqueIdentifier,
        { path: '/api/regions/specifications/' + regionSpecificationId })
      return response.ok
    },

    async updateRegionSpecification(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionSpecificationId, regionSpecification) {
      const response = await backendHandler.patch(globalUniqueIdentifier, {
        path: '/api/regions/specifications/' + regionSpecificationId,
        data: { value: regionSpecification },
      })
      return response.ok
    },
    async createRegionSpecification(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionSpecificationId, regionSpecification) {
      const response = await backendHandler.post(globalUniqueIdentifier, {
        path: '/api/regions/specifications/' + regionSpecificationId,
        data: { value: regionSpecification },
      })
      return response.ok
    },

    async deleteRegionType(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionTypeId) {
      const response = await backendHandler.delete(globalUniqueIdentifier, { path: '/api/regions/types/' + regionTypeId })
      return response.ok
    },

    async updateRegionType(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionTypeId, regionType) {
      const response = await backendHandler.patch(globalUniqueIdentifier, {
        path: '/api/regions/types/' + regionTypeId,
        data: { value: regionType },
      })
      return response.ok
    },
    async createRegionType(globalUniqueIdentifier = useInstanceStore().selectedInstanceId, regionTypeId, regionType) {
      const response = await backendHandler.post(globalUniqueIdentifier, {
        path: '/api/regions/types/' + regionTypeId,
        data: { value: regionType },
      })
      return response.ok
    },
  },
})
