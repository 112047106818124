<script setup>
import { computed } from 'vue'
import LegacyBarnDocumentationRenderer
  from '@/components/incidents/BarnDocumentation/LegacyBarnDocumentationRenderer.vue'
import NewBarnDocumentationRenderer from '@/components/incidents/BarnDocumentation/NewBarnDocumentationRenderer.vue'

const props = defineProps({ incident: { type: Object, default: () => ({}) } })
const isLegacyMode = computed(() => {
  if(props.incident.state === 'hidden') return true
  if(props.incident.messagePayload.specification.startTimestamp) return true
  if(props.incident.messagePayload.specification.timestamp) return true
  return false
})
</script>

<template>
  <LegacyBarnDocumentationRenderer
    v-if="isLegacyMode"
    :incident="incident"
  >
    <template #genericActions="slotProps">
      <slot
        name="genericActions"
        v-bind="slotProps"
      />
    </template>
    <template #commentButton="slotProps">
      <slot
        name="commentButton"
        v-bind="slotProps"
      />
    </template>
    <template #assignButton="slotProps">
      <slot
        name="assignButton"
        v-bind="slotProps"
      />
    </template>
    <template #default="slotProps">
      <slot v-bind="slotProps" />
    </template>
  </LegacyBarnDocumentationRenderer>
  <NewBarnDocumentationRenderer
    v-else
    :incident="incident"
  >
    <template #genericActions="slotProps">
      <slot
        name="genericActions"
        v-bind="slotProps"
      />
    </template>
    <template #commentButton="slotProps">
      <slot
        name="commentButton"
        v-bind="slotProps"
      />
    </template>
    <template #assignButton="slotProps">
      <slot
        name="assignButton"
        v-bind="slotProps"
      />
    </template>
    <template #default="slotProps">
      <slot v-bind="slotProps" />
    </template>
  </NewBarnDocumentationRenderer>
</template>
