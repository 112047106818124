<script setup>

import { onMounted, ref } from 'vue'
import TriggerLegacyBarnDoc from '@/components/incidentTriggers/TriggerLegacyBarnDoc.vue'
import TriggerNewBarnDoc from '@/components/incidentTriggers/TriggerNewBarnDoc.vue'
import { useIncidentStore } from '@/store/incident.js'

const displayMode = ref(null)

const props = defineProps({
  incident: { type: Object, default: null },
  prefillLatestValues: { type: Boolean, default: false },
  showCancelButton: { type: Boolean, default: false },
  shrinkable: { type: Boolean, default: false },
  preFilledFirstTimestamp: { type: Number, default: null },
  preFilledSecondTimestamp: { type: Number, default: null },
})

onMounted(async () => {
  const nonSimplifiedSchema = await useIncidentStore().getIncidentSchema('barnDocumentation')
  if(Object.keys(nonSimplifiedSchema.barnDocumentation.triggers).includes('observed')) {
    displayMode.value = 'new'
  } else {
    displayMode.value = 'legacy'
  }
})

const emit = defineEmits(['saved', 'cancel'])
</script>

<template>
  <TriggerLegacyBarnDoc
    v-if="displayMode === 'legacy'"
    v-bind="props"
    @saved="(data) => emit('saved', data)"
    @cancel="(data) => emit('cancel', data)"
  />
  <TriggerNewBarnDoc
    v-else-if="displayMode === 'new'"
    v-bind="props"
    :pre-filled-first-timestamp="preFilledFirstTimestamp"
    :pre-filled-second-timestamp="preFilledSecondTimestamp"
    @saved="(data) => emit('saved', data)"
    @cancel="(data) => emit('cancel', data)"
  />
</template>

<style scoped>

</style>
