<script setup>
import { computed, onMounted, ref } from 'vue'
import BarnDocumentation from '@/components/incidents/BarnDocumentation/BarnDocumentation.vue';
import BarnIntervention from '@/components/incidents/BarnIntervention.vue';
import GenericIncident from '@/components/incidents/GenericIncident.vue';
import DeadAnimalIncident from '@/components/incidents/DeadAnimalIncident.vue';
import TriggerIncident from '@/components/incidentTriggers/TriggerIncident.vue';
import { useInstanceStore } from '@/store/backendInstances';
import PenState from '@/components/incidents/PenState.vue';
import { useIncidentStore } from '@/store/incident';

const instanceStore = useInstanceStore()
const incidentStore = useIncidentStore()
import i18n from '../../plugins/i18n'

const { mobile } = useDisplay()
const { t } = i18n.global
import { useToast } from 'vue-toastification';
import IncidentAssignButton from '@/components/IncidentAssignButton.vue';
import IncidentCommentButton from '@/components/incidents/IncidentCommentButton.vue';
import { useDisplay } from 'vuetify';
import ShareButton from '@/components/ShareButton.vue';
import HealthCheck from '@/components/incidents/HealthCheck.vue'
import HideShowButtons from '@/components/incidents/HideShowButtons.vue'

const toast = useToast();
const props = defineProps(
  {
    incidentId: { type: String, default: null },
    incident: { type: Object, default: null },
    showMoreButton: { type: Boolean, default: true },
    showTriggerButton: { type: Boolean, default: true },
    canTriggerNewEventOverride: { type: Function, default: undefined },
  })
const emit = defineEmits(['reload'])

const showTriggerDialog = ref(false)
const closeIncidentDialog = ref(false)
const reopenIncidentDialog = ref(false)
const deleteIncidentDialog = ref(false)
const forceClose = ref(false)
const loading = ref(false)

const shareUrl = computed(() => {
  if(!incidentToUse.value) return null
  return `${window.location.origin}/instance/${instanceStore.selectedInstanceId}/incident/${incidentToUse.value.incidentId}`
})
const canHideAndShowIncident = computed(() => {
  if(!incidentToUse.value) return null
  return instanceStore.hasAnyPermission([
    'incidents.manage',
    'incidents.manage.advanced',
    `incidents.${incidentToUse.value.type}.manage`,
  ])
})
const incidentToUse = computed(() => {
  return props.incident ? props.incident : selfLoadedIncident.value
})
const incidentComponent = computed(() => {
  if(!incidentToUse.value) return null
  switch (incidentToUse.value.type) {
  case 'barnDocumentation':
    return BarnDocumentation;
  case 'barnIntervention':
    return BarnIntervention;
  case 'deadAnimal':
    return DeadAnimalIncident;
  case 'penState':
    return PenState;
  case 'healthCheck':
    return HealthCheck;
  default:
    return GenericIncident
  }
})

const canTriggerNewEvent = computed(() => {
  if(!incidentToUse.value) return false
  if (props.canTriggerNewEventOverride) return props.canTriggerNewEventOverride(props)
  if (!instanceStore.hasAnyPermission([
    'incidents.manage',
    'incidents.manage.advanced',
    `incidents.${incidentToUse.value.type}.manage`])) return false
  return incidentToUse.value.state !== 'closed'
})

const canDeleteIncident = computed(() => {
  return instanceStore.hasPermission('incidents.manage.advanced');
})

const canCloseIncident = computed(() => {
  if(!incidentToUse.value) return false
  if (!instanceStore.hasAnyPermission([
    'incidents.manage',
    'incidents.manage.advanced',
    `incidents.${incidentToUse.value.type}.manage`])) return false
  return incidentToUse.value.state !== 'closed'
})

const canForceCloseIncident = computed(() => {
  if (!canCloseIncident.value) return false
  return instanceStore.hasPermission('incidents.manage.advanced');
})

const canReopenIncident = computed(() => {
  if(!incidentToUse.value) return false
  if (!instanceStore.hasAnyPermission([
    'incidents.manage',
    'incidents.manage.advanced',
    `incidents.${incidentToUse.value.type}.manage`])) return false
  return incidentToUse.value.state === 'closed'
})


async function closeIncident() {
  if(!incidentToUse.value) return
  loading.value = true
  const closeResult = await incidentStore.closeIncident(incidentToUse.value.incidentId, forceClose.value)
  loading.value = false
  if (closeResult === null) {
    toast.error(t('toast.incident.close.error'))
  } else {
    toast.success(t('toast.incident.close.success'))
    closeIncidentDialog.value = false
    emit('reload')
  }
}

async function reopenIncident() {
  if(!incidentToUse.value) return
  loading.value = true
  const reopenResult = await incidentStore.reopenIncident(incidentToUse.value.incidentId)
  loading.value = false
  if (reopenResult === null) {
    toast.error(t('toast.incident.reopen.error'))
  } else {
    toast.success(t('toast.incident.reopen.success'))
    reopenIncidentDialog.value = false
    emit('reload')
  }
}

async function deleteIncident() {
  if(!incidentToUse.value) return
  loading.value = true
  const deleteResult = await incidentStore.deleteIncident(incidentToUse.value.incidentId)
  loading.value = false
  if (deleteResult === null) {
    toast.error(t('toast.incident.delete.error'))
  } else {
    toast.success(t('toast.incident.delete.success'))
    deleteIncidentDialog.value = false
    emit('reload')
  }
}
const selfLoadedIncident = ref(null)
onMounted(async () => {
  if(!props.incident && props.incidentId) {
    selfLoadedIncident.value = await incidentStore.getIncident(props.incidentId)
  }
})


</script>

<template>
  <component
    :is="incidentComponent"
    v-if="incidentToUse"
    :incident="incidentToUse"
  >
    <template #commentButton>
      <IncidentCommentButton :incident="incidentToUse" />
    </template>
    <template #assignButton>
      <IncidentAssignButton
        :incident="incidentToUse"
        @reload="$emit('reload')"
      />
    </template>
    <template #genericActions="{enableNewEventButton, newEventButtonText, disableManagementButtons, enableHideAndShowButtons}">
      <ShareButton
        :url="shareUrl"
        :text="$t('incidents.share_text')"
      />
      <v-btn
        v-if="showMoreButton"
        class="rounded-pill"
        @click="$router.push({name: 'IncidentView', params: {incidentId: incidentToUse.incidentId}})"
      >
        {{ $t("general_interface.buttons.more") }}
      </v-btn>
      <v-btn
        v-if="canTriggerNewEvent && showTriggerButton && enableNewEventButton"
        variant="outlined"
        color="success"
        class="rounded-pill"
      >
        {{ newEventButtonText }}
        <v-dialog
          v-model="showTriggerDialog"
          activator="parent"
          width="auto"
          :fullscreen="mobile"
        >
          <TriggerIncident
            :incident="incidentToUse"
            :show-cancel-button="true"
            @saved="showTriggerDialog = false; $emit('reload')"
            @cancel="showTriggerDialog = false;"
          />
        </v-dialog>
      </v-btn>
      <v-btn
        v-if="!disableManagementButtons && (canDeleteIncident || canCloseIncident)"
        variant="outlined"
        class="rounded-pill"
      >
        {{ $t("general_interface.buttons.manage") }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-if="canCloseIncident"
              :title="$t('incidents.managing.close.title')"
              value="close"
            >
              <v-dialog
                v-model="closeIncidentDialog"
                activator="parent"
                width="auto"
              >
                <v-layout-card :loading="loading">
                  <v-card-title>{{ $t('incidents.managing.close.title') }}</v-card-title>
                  <v-card-text>
                    <v-alert type="info">
                      {{ $t('incidents.managing.close.text') }}
                    </v-alert>

                    <v-checkbox
                      v-if="canForceCloseIncident"
                      v-model="forceClose"
                      :label="$t('incidents.managing.close.force_close')"
                    />
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      color="error"
                      variant="outlined"
                      class="rounded-pill"
                      @click="closeIncidentDialog = false"
                    >
                      {{ $t("general_interface.buttons.cancel") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      variant="flat"
                      class="rounded-pill"
                      @click="closeIncident"
                    >
                      {{ $t('incidents.managing.close.button') }}
                    </v-btn>
                  </v-card-actions>
                </v-layout-card>
              </v-dialog>
            </v-list-item>
            <v-list-item
              v-if="canReopenIncident"
              :title="$t('incidents.managing.reopen.button')"
              value="reopen"
            >
              <v-dialog
                v-model="reopenIncidentDialog"
                activator="parent"
                width="auto"
              >
                <v-layout-card :loading="loading">
                  <v-card-title>{{ $t("incidents.managing.reopen.title") }}</v-card-title>
                  <v-card-text>
                    <v-alert type="info">
                      {{ $t('incidents.managing.reopen.text') }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      color="error"
                      variant="outlined"
                      class="rounded-pill"
                      @click="reopenIncidentDialog = false"
                    >
                      {{ $t('general_interface.buttons.cancel') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      variant="flat"
                      class="rounded-pill"
                      @click="reopenIncident"
                    >
                      {{ $t('incidents.managing.reopen.button') }}
                    </v-btn>
                  </v-card-actions>
                </v-layout-card>
              </v-dialog>
            </v-list-item>

            <v-list-item
              v-if="canDeleteIncident"
              :title="$t('incidents.managing.delete.button')"
              value="delete"
            >
              <v-dialog
                v-model="deleteIncidentDialog"
                activator="parent"
                width="auto"
              >
                <v-layout-card :loading="loading">
                  <v-card-title>{{ $t('incidents.managing.delete.title') }}</v-card-title>
                  <v-card-text>
                    <v-alert type="info">
                      {{ $t('incidents.managing.delete.text') }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      color="error"
                      variant="outlined"
                      class="rounded-pill"
                      @click="deleteIncidentDialog = false"
                    >
                      {{ $t('general_interface.buttons.cancel') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      variant="flat"
                      class="rounded-pill"
                      @click="deleteIncident"
                    >
                      {{ $t('incidents.managing.delete.button') }}
                    </v-btn>
                  </v-card-actions>
                </v-layout-card>
              </v-dialog>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
      <HideShowButtons
        v-if="enableHideAndShowButtons && canHideAndShowIncident"
        :incident="incidentToUse"
        @update-incident="emit('reload')"
      />
    </template>
  </component>
</template>
