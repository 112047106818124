<script setup>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import { humanReadableTimestamp } from '@/composables/datetime.js'
import WYSIWYG from '@/components/WYSIWYG.vue'
import { useCamerasStore } from '@/store/cameras.js'
import { useCameraName } from '@/composables/cameraHelpers.js'
const { t } = useI18n();

const props = defineProps({ incident: { type: Object, default: () => ({}) } })


const humanReadableCameras = ref('')

const humanReadableSpecificationType = computed(() => {
  const specification = props.incident.messagePayload.specification
  const type = specification.type
  return t(`incidents.barnDocumentation.specificationTypes.${type}`)
})

const humanReadableClassification = computed(() => {
  const classification = props.incident.messagePayload.classification
  return t(`incidents.barnDocumentation.classifications.${classification}`)
})

const newEventButtonText = computed(() => {
  return t('incidents.barnDocumentation.actions.finish')
})

const isTimestampIncident = computed(() => {
  return props.incident.state === 'closed'
    && props.incident.firstEventTimestamp === props.incident.lastEventTimestamp
})

const isGlobal = computed(() => {
  return (props.incident.messagePayload.specification.location === 'global')
})

const wasCreatedAutomatically = computed(() => {
  return (props.incident.triggeredBy.entityType !== 'account')
})


const hasRegions = computed(() => {
  if(isGlobal.value) return false
  return Object.keys(props.incident.messagePayload.specification.location).includes('regionSpecification')
})
const hasCameras = computed(() => {
  if(isGlobal.value) return false
  return Object.keys(props.incident.messagePayload.specification.location).includes('cameraId')
})

const humanReadableRegions = computed(() => {
  if(!hasRegions.value) return null
  const regionSpecification = props.incident.messagePayload.specification.location.regionSpecification
  return regionSpecification.join(', ')
})

onMounted(async () => {
  if (hasCameras.value) {
    await useCamerasStore().keepCamerasLoaded()
    const cameraNames = []
    for (const cameraId of props.incident.messagePayload.specification.location.cameraId) {
      cameraNames.push(useCameraName(cameraId))
    }
    humanReadableCameras.value = cameraNames.join(', ')
  }
})

</script>

<template>
  <v-layout-card>
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-card-title>
        {{ humanReadableSpecificationType }}
      </v-card-title>
      <v-card-subtitle
        v-if="incident.state === 'created'"
      >
        <v-alert type="warning" variant="flat" density="compact" >
          {{ $t("incidents.barnDocumentation.not_yet_finished") }}

        </v-alert>
      </v-card-subtitle>
      <v-card-subtitle v-if="incident.hidden === true">
        {{ $t("incidents.barnDocumentation.hidden") }}
      </v-card-subtitle>
    </v-row>

    <v-card-text>
      <v-list>
        <v-list-item
          :title="$t('incidents.barnDocumentation.titles.classification')"
          :subtitle="humanReadableClassification"
          prepend-icon="mdi-list-box"
        />
        <v-list-item
          :title="$t('incidents.barnDocumentation.titles.specificationType')"
          :subtitle="humanReadableSpecificationType"
          prepend-icon="mdi-label"
        />
        <v-list-item
          v-if="isTimestampIncident"
          :title="$t('incidents.barnDocumentation.titles.timestamp')"
          :subtitle="humanReadableTimestamp(incident.firstEventTimestamp)"
          prepend-icon="mdi-clock"
        />
        <v-list-item
          v-if="!isTimestampIncident"
          :title="$t('incidents.barnDocumentation.titles.startTimestamp')"
          :subtitle="humanReadableTimestamp(incident.firstEventTimestamp)"
          prepend-icon="mdi-clock-start"
        />
        <v-list-item
          v-if="!isTimestampIncident && incident.state === 'closed'"
          :title="$t('incidents.barnDocumentation.titles.endTimestamp')"
          :subtitle="humanReadableTimestamp(incident.lastEventTimestamp)"
          prepend-icon="mdi-clock-end"
        />
      </v-list>

      <v-list-item
        v-if="isGlobal"
        :title="$t('incidents.barnDocumentation.titles.location')"
        :subtitle="$t('incidents.barnDocumentation.global')"
        prepend-icon="mdi-earth"
      />
      <v-list-item
        v-if="hasCameras"
        :title="$t('incidents.barnDocumentation.titles.cameras')"
        :subtitle="humanReadableCameras"
        prepend-icon="mdi-video"
      />
      <v-list-item
        v-if="hasRegions"
        :title="$t('incidents.barnDocumentation.titles.regions')"
        :subtitle="humanReadableRegions"
        prepend-icon="mdi-map-marker"
      />
      <v-list-item
        v-if="wasCreatedAutomatically"
        :title="$t('incidents.barnDocumentation.titles.created_automatically')"
        :subtitle="$t('incidents.barnDocumentation.created_automatically')"
        prepend-icon="mdi-robot"
      />
      <v-list-item
        v-if="!wasCreatedAutomatically"
        :title="$t('incidents.barnDocumentation.titles.creator')"
        :subtitle="incident.triggeredBy.displayName"
        prepend-icon="mdi-account"
      />

      <v-list-item
        v-if="incident.hidden === true"
        :title="$t('incidents.barnDocumentation.titles.hideReason')"
        prepend-icon="mdi-file-question"
      >
        <WYSIWYG
          :model-value="incident.hiddenNote"
          :read-only="true"
        />
      </v-list-item>

      <v-list-item
        v-if="incident.messagePayload.description"
        :title="$t('incidents.barnDocumentation.titles.description')"
        prepend-icon="mdi-text-box"
        class="my-2"
      >
        <WYSIWYG
          :model-value="incident.messagePayload.description"
          :read-only="true"
        />
      </v-list-item>
    </v-card-text>

    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{enableNewEventButton: true, newEventButtonText: newEventButtonText, enableHideAndShowButtons: true}"
      />
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
