<script setup>
import CanvasRenderer from '@/components/CanvasRenderer.vue'
import { useCanvasStore } from '@/store/canvas'
import { computed, onMounted, ref, provide } from 'vue'
import { DateTime } from 'luxon'
import SimpleLineChart from '@/components/charts/SimpleLineChart.vue'
import NightvisionTimelineChart from '@/components/charts/NightvisionTimelineChart.vue'
import PenBasedRegionChart from '@/components/charts/PenBasedRegionChart.vue'

const canvasStore = useCanvasStore()

const timestamp = ref(DateTime.now().startOf('second'))

const baseCanvas = computed(() => {
  return canvasStore.getBaseCanvas()
})
provide('timeSetup', ref({
  start: timestamp.value.toMillis() - 1000 * 60 * 60 * 24 * 4,
  end: timestamp.value.toMillis(),
  timeResolution: 1000 * 60 * 10,
}))

// eslint-disable-next-line no-unused-vars
const allPigLabels = [
  'piglet',
  'piglet_lying',
  'piglet_standing',
  'piglet_sitting',
  'pig',
  'pig_sitting',
  'pig_standing',
  'pig_lying',
  'sow_lying_belly',
  'sow_lying_side',
  'sow_sitting',
  'sow_standing',
]

const allStandingPigs = [
  'piglet_standing',
  'pig_standing',
  'sow_standing',
]
const allNonStandingPigs = [
  'piglet',
  'piglet_lying',
  'piglet_sitting',
  'pig',
  'pig_sitting',
  'pig_lying',
  'sow_lying_belly',
  'sow_lying_side',
  'sow_sitting',
]

onMounted(() => {
  timestamp.value = DateTime.now().startOf('second')
  canvasStore.keepBaseCanvasLoaded()
})
</script>

<template>
  <v-row no-gutters>
    <v-col :cols="12">
      <v-layout-card
        class="px-0"
        style="height: 60vh"
      >
        <CanvasRenderer
          v-if="baseCanvas"
          :disable-zoom-buttons="true"
          :override-canvas="baseCanvas"
          :disable-element-picker="false"
          :disable-time-jumper="false"
          :disable-navigation="false"
          :disable-scrolling="true"
          :shrink-all-overlays-by-default="true"
          :timestamp="timestamp"
        />
      </v-layout-card>
    </v-col>

    <v-col :cols="12">
      <SimpleLineChart
        :disable-control-row="false"
        :aggregate="false"
        measurement="speed"
        field="speed"
        :title="$t('charts.titles.speed')"
        :subtitle="$t('charts.subtitles.speed')"
      />
    </v-col>
    <v-col :cols="12">
      <NightvisionTimelineChart
        :default-collapsed="false"
        :aggregate="false"
        :title="$t('charts.titles.nightvision_range_bar')"
      />
    </v-col>
    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'stalltechnik'"
        :specified-labels="allNonStandingPigs"
        :subtitle="$t('charts.subtitles.regionsBarnEquipment_non_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :fixed-region-specifications="['fuetterung']"
        :height="600"
      />
    </v-col>
    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'stalltechnik'"
        :specified-labels="allStandingPigs"
        :subtitle="$t('charts.subtitles.regionsBarnEquipment_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :fixed-region-specifications="['fuetterung']"
        :height="600"
      />
    </v-col>

    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'stalltechnik'"
        :specified-labels="allNonStandingPigs"
        :subtitle="$t('charts.subtitles.regions_activity_areas_non_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :fixed-region-specifications="['traenke']"
        :height="600"
      />
    </v-col>

    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'stalltechnik'"
        :specified-labels="allStandingPigs"
        :subtitle="$t('charts.subtitles.regions_activity_areas_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :fixed-region-specifications="['traenke']"
        :height="600"
      />
    </v-col>

    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'funktionsbereich'"
        :specified-labels="allNonStandingPigs"
        :subtitle="$t('charts.subtitles.regions_functional_areas_non_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :height="600"
      />
    </v-col>

    <v-col :cols="12">
      <PenBasedRegionChart
        :aggregate-labels="true"
        :disable-control-row="false"
        :region-type="'funktionsbereich'"
        :specified-labels="allStandingPigs"
        :subtitle="$t('charts.subtitles.regions_functional_areas_standing_pigs')"
        :title="$t('charts.titles.regions')"
        :height="600"
      />
    </v-col>
  </v-row>
</template>
