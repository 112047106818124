<script setup>
import { loadNightVisionMeasurement } from '@/composables/charts';
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue';
import { onMounted, ref, watch } from 'vue';
import { useTheme } from 'vuetify';
import { useCamerasStore } from '@/store/cameras.js';

const props = defineProps({
  cameraIds: { type: Array, default: () => [] },
  aggregate: { type: Boolean, default: false },
  title: { type: String, default: null },
  subtitle: { type: String, default: '' },
  defaultCollapsed: { type: Boolean, default: false },
});

const camerasStore = useCamerasStore();
const canBeRendered = ref(false);
const minHeight = ref(1500);

async function dataLoader(cameraIds, timeSetup, abortSignal) {
  const data = await loadNightVisionMeasurement({
    cameraIds: cameraIds,
    timeResolution: 1000 * 60,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregateCameras: props.aggregate,
    chartType: 'rangeBar',
  }, abortSignal);
  return data;
}
watch(
  () => camerasStore.getNonDecommissionedCameras,
  () => {
    canBeRendered.value = false;
    const cameras = camerasStore.getNonDecommissionedCameras;
    minHeight.value = cameras.length * 20;
    canBeRendered.value = true;
  },
  { immediate: true, deep: true },
);

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded();
});
</script>

<template>
  <CameraBaseChart
    v-if="canBeRendered"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    chart-type="xrange"
    :subtitle="subtitle"
    :default-collapsed="defaultCollapsed"
    :title="title"
    :height="Math.max(minHeight + 200, 600)"
    :options="{
      chart: {
        scrollablePlotArea: {
          minHeight: minHeight,
        },
        backgroundColor: useTheme().current.value.colors.surface,
      },
      legend: { enabled: false },
      xAxis: {
        backgroundColor: useTheme().current.value.colors.surface,
      },
      yAxis: {
        backgroundColor: useTheme().current.value.colors.surface,
        labels: {
          style: {
            color: useTheme().current.value.colors.onSurface,
          },
        },

        title: { text: '' },
        type: 'category',
        minPadding: 0,
        reversed: true,
      },
    }"
  />
</template>

<style scoped></style>
