import IncidentChartAnnotationProvider
  from '@/components/charts/IncidentChartAnnotationProviders/IncidentChartAnnotationProvider.js'
import { useIncidentStore } from '@/store/incident.js'
import { randomString } from '@/composables/helperFunctions.js'
import IncidentDisplay from '@/components/incidents/IncidentDisplay.vue'

class GenericTimespanIncidentChartAnnotationProvider extends IncidentChartAnnotationProvider {
  constructor(
    incidentType,
    additionalFilters = undefined,
    shapeFillColor='rgba(255, 0, 0, 0.4)',
    deHighlightedFillColor='rgba(255, 0, 0, 0.1)')
  {
    super()
    this.incidentType = incidentType
    this.additionalFilters = additionalFilters
    this.shapeFillColor = shapeFillColor
    this.deHighlightedFillColor = deHighlightedFillColor
  }

  async loadData(startTimestamp, endTimestamp) {
    const incidentStore = useIncidentStore()
    this.incidents = await incidentStore.loadIncidentsOpenBetweenSpecificTimestamps(
      [this.incidentType],
      startTimestamp,
      endTimestamp,
      undefined,
      undefined,
      this.additionalFilters,
    )
  }

  getLabelText(incident) {
    return incident.type
  }

  buildAnnotations(startTimestamp, endTimestamp) {
    this.annotations.length = 0
    if(!this.incidents) { return }
    for (const incident of this.incidents) {
      const labelStartTimestamp = Math.max(incident.firstEventTimestamp, startTimestamp)
      const labelEndTimestamp = Math.min(incident.lastEventTimestamp, endTimestamp)

      const shapeStartTimestamp = incident.firstEventTimestamp
      const shapeEndTimestamp = incident.state === 'closed' ? incident.lastEventTimestamp : Number.MAX_SAFE_INTEGER

      const annotationGroupId = randomString(20)
      const shapeAnnotation = {
        annotationGroupId: annotationGroupId,
        provider: this,
        incident: incident,
        incidentId: incident.incidentId,
        draggable: '',
        zIndex: 1,
        shapes: [{
          type: 'path',
          fill: this.shapeFillColor,
          strokeWidth: 0,
          points: [
            { xAxis: 0, yAxis: 0, x: shapeStartTimestamp, y: Number.MIN_SAFE_INTEGER }, //bottom left
            { xAxis: 0, yAxis: 0, x: shapeStartTimestamp, y: Number.MAX_SAFE_INTEGER }, //top left
            { xAxis: 0, yAxis: 0, x: shapeEndTimestamp, y: Number.MAX_SAFE_INTEGER }, //top right
            { xAxis: 0, yAxis: 0, x: shapeEndTimestamp, y: Number.MIN_SAFE_INTEGER }, //bottom right
          ],
        }],
      }
      const labelRenderPosition = this.getRenderPositionForLabelAtXValue(
        (labelEndTimestamp + labelStartTimestamp) / 2,
        false)
      const labelAnnotation = {
        events: {
          click: () => {
            this.showAnnotationDialog(IncidentDisplay, { incident: incident })
          },
          touchstart: e => {
            e.preventDefault();
            this.showAnnotationDialog(IncidentDisplay, { incident: incident });
          },
          add: event => {
            event.target.graphic.on('mouseenter', () => {
              this.highlightAnnotationsForAnnotationGroup(annotationGroupId)
            })
            event.target.graphic.on('mouseleave', () => {
              this.resetAnnotationHighlight()
            })
          },
        },
        annotationGroupId: annotationGroupId,
        provider: this,
        incident: incident,
        incidentId: incident.incidentId,
        draggable: '',
        zIndex: 6,
        allowOverlap: true,
        useHTML: false,
        cropped: false,
        labels: [{
          style: { opacity: 1 },
          shape: 'rect',
          verticalAlign: labelRenderPosition.verticalAlign,
          point: labelRenderPosition.point,
          text: this.getLabelText(incident),
        }],
      }
      this.annotations.push(labelAnnotation)
      this.annotations.push(shapeAnnotation)

    }
  }

  highlightAnnotations(annotationGroupId) {
    this.annotations.forEach(annotation => {
      if(annotation.labels) {
        annotation.labels.forEach(label => {
          label.style.opacity = annotationGroupId === annotation.annotationGroupId ? 1 : 0.2
        })
      }
      if(annotation.shapes) {
        annotation.shapes.forEach(shape => {
          shape.fill =
            annotationGroupId === annotation.annotationGroupId
              ? this.shapeFillColor
              : this.deHighlightedFillColor
        })
      }
    })
  }
  resetHighlight() {
    this.annotations.forEach(annotation => {
      if(annotation.labels) {
        annotation.labels.forEach(label => {
          label.style.opacity = 1
        })
      }
      if(annotation.shapes) {
        annotation.shapes.forEach(shape => {
          shape.fill = this.shapeFillColor
        })
      }
    })
  }
}

export default GenericTimespanIncidentChartAnnotationProvider
