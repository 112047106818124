import { useInstanceStore } from '@/store/backendInstances';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Buffer } from 'buffer';
import { reactive } from 'vue';
import { useSettingsStore } from '@/store/settings';
import SyncableQueue from '@/syncing/SyncableQueue.js';
import { useLogsStore } from '@/store/logs.js';

class BackendAPI {
  constructor(globalUniqueIdentifier, { minimumFullConnectionCheckInterval = 1000 * 30, testMode = false }) {
    this.maximumConcurrentRequests = 6
    this.globalUniqueIdentifier = globalUniqueIdentifier
    this.requestsWaitingForAuthentication = []
    this.requestQueue = []
    this.testMode = testMode
    this.fullCheckPromise = null
    this.quickCheckPromise = null
    this.minimumFullConnectionCheckInterval = minimumFullConnectionCheckInterval
    this.currentlyPerformingFullCheck = false
    this.currentlyPerformingQuickCheck = false
    this.lastFullConnectionCheck = null
    this.discoveryObject = useInstanceStore().instances[this.globalUniqueIdentifier].discoveryObject
    this.lastAccessTokenRefresh = 0
    this.currentlyRefreshingAccessToken = false

    this.accessTokenRefreshPromise = null

    this.quickConnectionCheckIntervalWhileOnline = 1000 * 30
    this.fullConnectionCheckIntervalWhileOnline = 1000 * 60 * 10
    this.fullConnectionCheckIntervalWhileOffline = 1000 * 60 * 5

    this.maxRetries = 3
    this.accessibleState = reactive({
      isOnline: false,
      loggedIn: false,
      accessToken: null,
      accountId: null,
      connectionStrategy: null,
      concurrentRequests: 0,
    })
    this.currentConnectionStrategy = undefined
  }

  initialize() {
    this.updateCurrentConnectionStrategy(null, true)
    this.validateRefreshToken()
    this.triggerScheduledFullConnectionCheck().then().catch(e => {
      useLogsStore().addLogEntry({
        message: 'Failed to perform initial full connection check',
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'ERROR',
        error: e,
      })
    })
  }

  async triggerScheduledQuickConnectionCheck() {
    if (!this.currentlyPerformingQuickCheck) {
      this.quickCheckPromise = this.quickConnectionCheck()
    }
    return this.quickCheckPromise
  }

  async triggerScheduledFullConnectionCheck() {
    if (!this.currentlyPerformingFullCheck) {
      this.fullCheckPromise = this.fullConnectionCheck()
    }
    return this.fullCheckPromise
  }

  updateCurrentConnectionStrategy(connectionStrategy, suppressLogs = false) {
    const onlineStatusChanged = this.currentConnectionStrategy !== connectionStrategy
    this.currentConnectionStrategy = connectionStrategy
    this.accessibleState.connectionStrategy = connectionStrategy
    this.accessibleState.isOnline = connectionStrategy !== null
    if (!onlineStatusChanged) return
    if (this.accessibleState.isOnline) {
      if(!suppressLogs)
        useLogsStore().addLogEntry({
          'message': 'Server was detected as Online',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
          tag: 'BackendAPI',
          level: 'DEBUG',
        })
      SyncableQueue.executeJobsForGlobalUniqueIdentifier(this.globalUniqueIdentifier)
    } else {
      if(!suppressLogs)
        useLogsStore().addLogEntry({
          'message': 'Server was detected as Offline!',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
          tag: 'BackendAPI',
          level: 'DEBUG',
        })
    }
    clearInterval(this.quickOnlineCheckInterval)
    clearInterval(this.fullOnlineCheckInterval)
    clearInterval(this.fullOfflineCheckInterval)

    if (connectionStrategy === null) {
      this.fullOfflineCheckInterval = setInterval(() => this.triggerScheduledFullConnectionCheck, this.fullConnectionCheckIntervalWhileOffline)
    } else {
      this.quickOnlineCheckInterval = setInterval(() => this.triggerScheduledQuickConnectionCheck, this.quickConnectionCheckIntervalWhileOnline)
      this.fullOnlineCheckInterval = setInterval(() => this.triggerScheduledFullConnectionCheck, this.fullConnectionCheckIntervalWhileOnline)
    }
  }

  async testConnectivity(url, signal) {
    if (!url) return [false, '', 'Strategy not used!'];
    try {
      const res = await axios.get(`${url.href}api/p`, { timeout: 10000, signal: signal });
      if (res.status >= 200 && res.status < 300) {
        return [true, url, ''];
      }
      return [false, url, `Server did not return the expected Result! ${res.status}`];
    } catch (e) {
      return [false, url, e.message];
    }
  }

  localUrl() {
    if (!this.discoveryObject.local) return false
    const localConfig = this.discoveryObject.local
    if (!localConfig.hostname || !localConfig.port || !localConfig.protocol) return false;
    return new URL(`${this.discoveryObject.local.protocol}://${this.discoveryObject.local.hostname}:${this.discoveryObject.local.port}`);
  }

  remoteUrl() {
    if (!this.discoveryObject.remote) return false
    const remoteConfig = this.discoveryObject.remote
    if (!remoteConfig.hostname || !remoteConfig.port || !remoteConfig.protocol) return false;
    return new URL(`${this.discoveryObject.remote.protocol}://${this.discoveryObject.remote.hostname}:${this.discoveryObject.remote.port}`);
  }

  gatewayUrl() {
    if (!this.discoveryObject.gateway) return false;
    const gatewayConfig = this.discoveryObject.gateway
    if (!gatewayConfig.id || !gatewayConfig.hostname || !gatewayConfig.port || !gatewayConfig.protocol) return false;
    return new URL(`${this.discoveryObject.gateway.protocol}://${this.discoveryObject.gateway.hostname}:${this.discoveryObject.gateway.port}/gw/${this.discoveryObject.gateway.id}/`);
  }

  async acceptEULA(version) {
    return this.fetchAccessToken(version)
  }

  getRefreshToken() {
    return useInstanceStore().instances[this.globalUniqueIdentifier]?.refreshToken
  }

  async validateRefreshTokenAndTryToLoginIfPossible() {
    if (this.validateRefreshToken()) return true
    await useInstanceStore().autoLoginIntoInstanceIfPossible(this.globalUniqueIdentifier);
    return this.validateRefreshToken();
  }

  validateRefreshToken() {
    const token = this.getRefreshToken()
    if (!token) {
      this.accessibleState.loggedIn = false;
      useLogsStore().addLogEntry({
        message: 'No Refresh Token Found',
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'DEBUG',
      })
      return false
    }
    try {
      const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
      if (!decodedToken.refresh || decodedToken.exp * 1000 <= Date.now()) {
        this.accessibleState.loggedIn = false
        return false
      }
      this.accessibleState.loggedIn = true
      this.accessibleState.accountId = decodedToken.uid
      useLogsStore().addLogEntry({
        message: 'Refresh Token Validated',
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'DEBUG',
      })
      return true
    } catch (e) {
      useLogsStore().addLogEntry({
        message: 'Failed to validate refresh token',
        error: e,
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'ERROR',
      })
      this.accessibleState.loggedIn = false
      return false
    }
  }

  async fetchAccessToken(acceptedEULAVersion = undefined, attempts = 0) {
    if (!(await this.validateRefreshTokenAndTryToLoginIfPossible())) {

      return false
    }
    const host = await this.getHost()
    if (!host) {
      return false;
    }
    const instanceStore = useInstanceStore()
    const refreshToken = instanceStore.instances[this.globalUniqueIdentifier].refreshToken

    const accessTokenRefreshAxiosConfig = {
      baseURL: host.href,
      url: 'api/login/refresh',
      method: 'POST',
      data: { refreshToken, acceptEula: acceptedEULAVersion || undefined },
      validateStatus: () => true,
    }
    try {
      const response = await axios(accessTokenRefreshAxiosConfig)
      if (response.status === 451) {
        useLogsStore().addLogEntry({
          message: 'EULA Refresh Required',
          tag: 'BackendAPI',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
          level: 'INFO',
        })
        instanceStore.eulaRefreshRequired[this.globalUniqueIdentifier] = true

        return false
      } else {
        instanceStore.eulaRefreshRequired[this.globalUniqueIdentifier] = false
      }
      if (response.status === 200) {
        this.accessibleState.accessToken = response.data.accessToken
        try {
          const permissionAxiosConfig = {
            baseURL: host.href,
            url: 'api/permissions',
            method: 'GET',
            headers: { Authorization: `Bearer ${this.accessibleState.accessToken}` },
            validateStatus: () => true,
          }
          const permissionResponse = await axios(permissionAxiosConfig)
          if (permissionResponse.status === 200) {
            useInstanceStore().instances[this.globalUniqueIdentifier].availablePermissions = permissionResponse.data
          }
        } catch (e) {
          useLogsStore().addLogEntry({
            message: 'Failed to fetch permissions',
            error: e,
            tag: 'BackendAPI',
            globalUniqueIdentifier: this.globalUniqueIdentifier,
            level: 'ERROR',
          })
        }
        return this.validateRefreshTokenAndTryToLoginIfPossible()
      }
      if (response.status === 403) {
        delete useInstanceStore().instances[this.globalUniqueIdentifier].refreshToken
        return this.validateRefreshTokenAndTryToLoginIfPossible()
      }
    } catch (e) {
      if(attempts < 3) {
        await new Promise(resolve => setTimeout(resolve, 500))
        return this.fetchAccessToken(acceptedEULAVersion, attempts + 1)
      }
      useLogsStore().addLogEntry({
        message: 'Failed to refresh access token',
        error: e,
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'ERROR',
      })
    }
  }

  async getNewAccessToken() {
    if (!this.currentlyRefreshingAccessToken) {
      this.currentlyRefreshingAccessToken = true
      this.accessTokenRefreshPromise = new Promise((resolve, reject) => {
        this.fetchAccessToken().then(result => {
          if (!result) throw new Error('failed to fetch Access Token!')
          this.lastAccessTokenRefresh = DateTime.now().toMillis()
          this.currentlyRefreshingAccessToken = false
          resolve()
        }).catch(e => {
          useLogsStore().addLogEntry({
            message: 'Failed to refresh access token',
            error: e,
            tag: 'BackendAPI',
            globalUniqueIdentifier: this.globalUniqueIdentifier,
            level: 'ERROR',
          })
          this.currentlyRefreshingAccessToken = false
          reject(e)
        }).finally(() => {
          this.currentlyRefreshingAccessToken = false
        })
      })
    }
    return this.accessTokenRefreshPromise
  }

  async getHost() {
    if (!this.currentConnectionStrategy || (useSettingsStore().forceConnectionStrategy && useSettingsStore().forcedConnectionStrategy !== this.currentConnectionStrategy)) {
      if (!this.currentlyPerformingFullCheck && ((this.lastFullConnectionCheck && this.lastFullConnectionCheck + this.minimumFullConnectionCheckInterval < Date.now()) || !this.lastFullConnectionCheck)) {
        if (!this.currentlyPerformingFullCheck) {
          this.fullCheckPromise = this.fullConnectionCheck()
        }
      }
      await this.fullCheckPromise
    }
    switch (this.currentConnectionStrategy) {
    case 'local': {
      return this.localUrl()
    }
    case 'remote': {
      return this.remoteUrl()
    }
    case 'gateway': {
      return this.gatewayUrl()
    }
    default:
      return null;
    }
  }

  async quickConnectionCheck() {
    let connectionPromise = null
    if (this.currentConnectionStrategy === 'local' && (!useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'local')) {
      connectionPromise = this.testConnectivity(this.localUrl());
    } else if (this.currentConnectionStrategy === 'gateway' && (!useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'gateway')) {
      connectionPromise = this.testConnectivity(this.gatewayUrl());
    } else if (this.currentConnectionStrategy === 'remote' && (!useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'remote')) {
      connectionPromise = this.testConnectivity(this.remoteUrl());
    }
    if (connectionPromise !== null) {
      const connectionResponse = await connectionPromise;
      if (connectionResponse[0] === true) return
    }
    this.updateCurrentConnectionStrategy(null)
  }

  async fullConnectionCheck() {
    this.currentlyPerformingFullCheck = true
    const now = Date.now()
    const remoteController = new AbortController()
    const gatewayController = new AbortController()
    let localSuccessful = false
    let remoteSuccessful = false
    let gatewaySuccessful = false
    const promises = []

    if (!useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'local') {
      promises.push(this.testConnectivity(this.localUrl()).then(result => {
        if (result[0] === true) {
          localSuccessful = true
          remoteController.abort()
          gatewayController.abort()
          this.updateCurrentConnectionStrategy('local')
        }
      }).catch(e => {
        useLogsStore().addLogEntry({
          message: 'Local Connection Check crashed?',
          level: 'ERROR',
          error: e,
          tag: 'BackendAPI',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
        })
      }))
    }

    if (!gatewayController.signal.aborted && !localSuccessful && !useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'gateway') {
      promises.push(this.testConnectivity(this.gatewayUrl()).then(result => {
        if (result[0] === true) {
          gatewaySuccessful = true
          remoteController.abort()
          if (!localSuccessful) {
            this.updateCurrentConnectionStrategy('gateway')
          }
        }
      }).catch(e => {
        useLogsStore().addLogEntry({
          message: 'Local Connection Check crashed?',
          level: 'ERROR',
          error: e,
          tag: 'BackendAPI',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
        })
      }))
    }

    if (!remoteController.signal.aborted && !localSuccessful && !gatewaySuccessful && !useSettingsStore().forceConnectionStrategy || useSettingsStore().forcedConnectionStrategy === 'remote') {
      promises.push(this.testConnectivity(this.remoteUrl()).then(result => {
        if (result[0] === true) {
          remoteSuccessful = true
          if (!localSuccessful && !gatewaySuccessful) {
            this.updateCurrentConnectionStrategy('remote')
          }
        }
      }).catch(e => {
        useLogsStore().addLogEntry({
          message: 'Local Connection Check crashed?',
          level: 'ERROR',
          error: e,
          tag: 'BackendAPI',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
        })
      }))
    }
    if (promises.length)
      await Promise.allSettled(promises)
    this.lastFullConnectionCheck = now
    if (!localSuccessful && !remoteSuccessful && !gatewaySuccessful) {
      this.updateCurrentConnectionStrategy(null)
    }
    this.currentlyPerformingFullCheck = false;
  }

  addRequest(config = {}) {
    const request = new BackendRequest(config)
    this.requestQueue.push(request)
    this.executeQueue()
    return request
  }

  addRequestBatch(configs = []) {
    const requests = configs.map(config => new BackendRequest(config))
    this.requestQueue.push(...requests)
    this.executeQueue()
    return requests
  }

  findNextRequest() {
    if (this.requestQueue.length === 0 && this.requestsWaitingForAuthentication.length === 0) {
      return null
    }
    if (this.requestsWaitingForAuthentication.length > 0) {
      return this.requestsWaitingForAuthentication.shift()
    }
    this.requestQueue.sort((a, b) => a.priority - b.priority)
    return this.requestQueue.shift()
  }

  evictPathBoundRequests(path) {
    for (const requestQueueElement of this.requestQueue) {
      if (requestQueueElement.isPathBound && requestQueueElement.boundToPath === path) {
        requestQueueElement.abortController.abort()
      }
    }
    for (const requestQueueElement of this.requestsWaitingForAuthentication) {
      if (requestQueueElement.isPathBound && requestQueueElement.boundToPath === path) {
        requestQueueElement.abortController.abort()
      }
    }
  }

  async executeRequest(request) {
    try {
      if ((request.requiresAuth && !this.accessibleState.accessToken) || (request.forceReAuthentication === true && request.executionTimestamp > this.lastAccessTokenRefresh)) {
        try {
          await this.getNewAccessToken()
        } catch (e) {
          request.reject(e, this.globalUniqueIdentifier, request.path)
          return
        }
      }
      const host = await this.getHost();
      if (!host) {
        request.reject(new Error('No Host Available'), this.globalUniqueIdentifier, request.path)
      } else {
        const {
          needsAuthentication,
          maybeRetry,
          error,
        } = await request.execute(host, this.globalUniqueIdentifier, this.accessibleState.accessToken)
        if (needsAuthentication) {
          const clonedRequest = request.clone()
          this.requestsWaitingForAuthentication.push(clonedRequest)
        }
        if (maybeRetry) {
          if (request.attempts < this.maxRetries) {
            const clonedRequest = request.clone()
            this.requestQueue.push(clonedRequest)
          } else {
            request.reject(error, this.globalUniqueIdentifier, request.path)
          }
        }

      }

    } catch (e) {
      useLogsStore().addLogEntry({
        message: 'Failed to execute request',
        error: e,
        tag: 'BackendAPI',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'ERROR',
      })
    }
  }

  executeQueue() {
    while (this.accessibleState.concurrentRequests < this.maximumConcurrentRequests) {
      const nextRequest = this.findNextRequest()
      if (!nextRequest) return
      this.accessibleState.concurrentRequests++
      this.executeRequest(nextRequest).finally(() => {
        this.accessibleState.concurrentRequests--
        this.executeQueue()
      })
    }
  }

  destroy() {
    clearInterval(this.quickOnlineCheckInterval)
    clearInterval(this.fullOnlineCheckInterval)
    clearInterval(this.fullOfflineCheckInterval)

    this.requestQueue.forEach(request => request.abortController.abort())
    this.requestQueue = []
    this.requestsWaitingForAuthentication.forEach(request => request.abortController.abort())
    this.requestsWaitingForAuthentication = []
  }

  async logOut() {
    this.addRequest({
      path: '/api/login/' + this.getRefreshToken(),
      method: 'DELETE',
      priority: 0,
      needsAuthentication: false,
    })
    delete useInstanceStore().instances[this.globalUniqueIdentifier].refreshToken
    this.validateRefreshToken()
  }
}

class BackendRequest {
  constructor(config = {}, executionTimestamp) {
    this.requiresAuth = config.requiresAuth !== false
    this.isPathBound = config.isPathBound === true
    this.boundToPath = backendHandler.currentPath
    this.method = config.method ? config.method : 'GET'
    this.priority = config.priority ? config.priority : 5
    this.path = config.path ? config.path : ''
    this.abortController = new AbortController()
    this.outsideAbortSignal = config.signal ? config.signal : null
    if (this.outsideAbortSignal) {
      this.outsideAbortSignal.addEventListener('abort', () => {
        this.abortController.abort()
      })
    }
    this.responseType = config.responseType ? config.responseType : 'json'
    this.onDownloadProgress = config.onDownloadProgress ? config.onDownloadProgress : null
    this.params = config.params ? config.params : []
    this.data = config.data ? config.data : null
    this.headers = config.headers ? config.headers : {}
    this.attempts = config.attempts ? config.attempts : 0
    this.mainPromise = new Promise((resolve, reject) => {
      this.promiseResolve = r => resolve(r);
      this.promiseReject = e => reject(e);
    })
    this.executionTimestamp = executionTimestamp
    this.forceReAuthentication = config.forceReAuthentication === true
  }

  async execute(host, globalUniqueIdentifier, accessToken) {
    this.executionTimestamp = DateTime.now().toMillis()
    const headers = {}
    let accessTokenAccountId = 'UNKNOWN'
    if (this.requiresAuth) {
      if (accessToken || useInstanceStore().eulaRefreshRequired[globalUniqueIdentifier]) {
        headers.Authorization = `Bearer ${accessToken}`
        try {
          const decodedToken = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString())
          accessTokenAccountId = decodedToken.uid
        } catch(e){
          useLogsStore().addLogEntry({
            message: 'Could not Decode Auth Token!',
            tag: 'BackendRequest',
            error: e,
            globalUniqueIdentifier,
            level: 'ERROR',
          })
        }
      } else {
        this.attempts = this.attempts + 1
        this.forceReAuthentication = true;
        return { needsAuthentication: true, maybeRetry: false }
      }
    }
    const axiosConfig = {
      signal: this.abortController.signal,
      baseURL: host.href,
      url: this.path,
      headers: headers,
      params: this.params,
      responseType: this.responseType,
      method: this.method,
      data: this.data,
      validateStatus: () => true,
      onDownloadProgress: this.onDownloadProgress,
    }
    try {
      if (this.abortController.signal.aborted || (this.outsideAbortSignal && this.outsideAbortSignal.aborted)) {
        this.reject(new Error('CanceledError'), globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
        return { needsAuthentication: false, maybeRetry: false, error: new Error('CanceledError') }
      }
      this.attempts = this.attempts + 1
      const axiosResponse = await axios(axiosConfig)
      if (!axiosResponse) throw new Error('No Response')
      if (this.requiresAuth && axiosResponse.status !== 451 && axiosResponse.status !== 401) {
        useInstanceStore().eulaRefreshRequired[globalUniqueIdentifier] = false
      }

      if (axiosResponse.status >= 200 && axiosResponse.status < 300) {
        this.resolve(axiosResponse, globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
        return { needsAuthentication: false, maybeRetry: false }
      }

      if (axiosResponse.status === 401) {
        if (this.requiresAuth && !this.forceReAuthentication) {
          this.forceReAuthentication = true;
          return { needsAuthentication: true, maybeRetry: false }
        }
        this.reject(new Error('Authentication failed'), globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
        return { needsAuthentication: false, maybeRetry: false }
      }

      if (axiosResponse.status === 451) {
        useInstanceStore().eulaRefreshRequired[globalUniqueIdentifier] = true;
        this.reject(new Error('EULA Refresh Required'), globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
        return { needsAuthentication: false, maybeRetry: false }
      }

      this.resolve(axiosResponse, globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
      return { needsAuthentication: false, maybeRetry: false }
    } catch (e) {
      if (e.name === 'CanceledError') {
        this.reject(e, globalUniqueIdentifier, this.path, this.requiresAuth, accessTokenAccountId)
        return { needsAuthentication: false, maybeRetry: false, error: e }
      }
      return { needsAuthentication: false, maybeRetry: true, error: e }
    }
  }

  resolve(response, globalUniqueIdentifier, path, requiresAuth, accountId) {
    const ok = response.status >= 200 && response.status < 300
    let error = undefined
    if (!ok) {
      error = new Error('Request Failed with Status Code ' + response.status)
      useLogsStore().addLogEntry({
        message: 'Request to ' + path + ' Failed with code ' + response.status + ', Requires Auth: ' + requiresAuth + ', Account: ' + accountId,
        tag: 'BackendRequest',
        error: error,
        globalUniqueIdentifier,
        level: 'ERROR',
      })
    }
    this.promiseResolve({
      ok: response.status >= 200 && response.status < 300,
      data: response.data,
      response: response,
      status: response.status,
      attempts: this.attempts,
      error: error,
    })
  }

  reject(error, globalUniqueIdentifier = null, path, requiresAuth, accountId) {
    const aborted = error.name === 'CanceledError' || error.message === 'CanceledError'
    if (!aborted) {
      useLogsStore().addLogEntry({
        message: '[' + path + '] : Request Failed , Requires Auth: ' + requiresAuth + ', Account: ' + accountId,
        error: error,
        tag: 'BackendRequest',
        globalUniqueIdentifier,
        level: 'ERROR',
      })
    }
    this.promiseResolve({
      ok: false,
      error: error,
      data: null,
      attempts: this.attempts,
      aborted: aborted,
    })
  }

  clone() {
    const clonedRequest = new BackendRequest({
      requiresAuth: this.requiresAuth,
      method: this.method,
      priority: this.priority,
      path: this.path,
      abortSignal: this.outsideAbortSignal,
      responseType: this.responseType,
      params: this.params,
      data: this.data,
      headers: this.headers,
      forceReAuthentication: this.forceReAuthentication,
      attempts: this.attempts,
    }, this.executionTimestamp)
    clonedRequest.mainPromise = this.mainPromise
    clonedRequest.promiseResolve = this.promiseResolve
    clonedRequest.promiseReject = this.promiseReject
    return clonedRequest
  }
}

class BackendHandler {
  constructor() {
    this.globalRequests = []
    this.instances = {}
    this.settings = {}
    this.currentPath = '/'
  }

  triggerRouteChange(oldPath, newPath) {
    this.currentPath = newPath
    if(oldPath === newPath) return
    for (const instance of Object.values(this.instances)) {
      instance.evictPathBoundRequests(oldPath)
    }
  }

  updateSettings(settings = {}) {
    this.settings = settings
  }

  getOrCreateBackendAPIInstance(globalUniqueIdentifier) {
    if (!Object.keys(useInstanceStore().instances).includes(globalUniqueIdentifier)) {
      const e = new Error('Instance not found')
      useLogsStore().addLogEntry({
        message: 'Instance not found: ' + globalUniqueIdentifier,
        tag: 'BackendHandler',
        globalUniqueIdentifier: null,
        error: e,
        level: 'ERROR',
      })
      throw e
    }
    if (this.instances[globalUniqueIdentifier] === undefined) {
      this.instances[globalUniqueIdentifier] = new BackendAPI(globalUniqueIdentifier, this.settings)
      this.instances[globalUniqueIdentifier].initialize()
    }
    return this.instances[globalUniqueIdentifier]
  }

  makeRequest(globalUniqueIdentifier = undefined, config = {}) {
    if (globalUniqueIdentifier === undefined) {
      globalUniqueIdentifier = useInstanceStore().selectedInstanceId
    }
    if (!globalUniqueIdentifier) {
      throw new Error('No Instance Selected')
    }
    const backendAPI = this.getOrCreateBackendAPIInstance(globalUniqueIdentifier)
    const request = backendAPI.addRequest(config)
    return request.mainPromise
  }

  get(globalUniqueIdentifier = undefined, config = {}) {
    return this.makeRequest(globalUniqueIdentifier, { ...config, method: 'GET' })
  }

  post(globalUniqueIdentifier = undefined, config = {}) {
    return this.makeRequest(globalUniqueIdentifier, { ...config, method: 'POST' })
  }

  put(globalUniqueIdentifier = undefined, config = {}) {
    return this.makeRequest(globalUniqueIdentifier, { ...config, method: 'PUT' })
  }

  delete(globalUniqueIdentifier = undefined, config = {}) {
    return this.makeRequest(globalUniqueIdentifier, { ...config, method: 'DELETE' })
  }

  patch(globalUniqueIdentifier = undefined, config = {}) {
    return this.makeRequest(globalUniqueIdentifier, { ...config, method: 'PATCH' })
  }

  makeRequestBatchForSingleInstance(globalUniqueIdentifier, configs = []) {
    const backendAPI = this.getOrCreateBackendAPIInstance(globalUniqueIdentifier)
    const requests = backendAPI.addRequestBatch(configs)
    return requests.map(request => request.mainPromise)
  }

  async makeGlobalRequest(config) {
    const axiosConfig = {
      baseURL: config.hostname,
      url: config.path,
      headers: config.headers,
      params: config.params,
      responseType: config.responseType,
      method: config.method ? config.method : 'GET',
      data: config.data,
      validateStatus: () => true,
    }
    try {
      const axiosResponse = await axios(axiosConfig)
      if (!axiosResponse) throw new Error('No Response')
      return {
        ok: axiosResponse.status >= 200 && axiosResponse.status < 300,
        data: axiosResponse.data,
        response: axiosResponse,
        status: axiosResponse.status,
      }
    } catch (error) {
      return { ok: false, error: error, data: null }

    }
  }

  async logOutOfInstance(globalUniqueIdentifier) {
    const instance = this.getOrCreateBackendAPIInstance(globalUniqueIdentifier)
    await instance.logOut()
  }

  destroyInstances() {
    for (const instance of Object.keys(this.instances)) {
      this.instances[instance].destroy()
      delete this.instances[instance]
    }
  }

  removeInstance(globalUniqueIdentifier) {
    if (this.instances[globalUniqueIdentifier]) {
      this.instances[globalUniqueIdentifier].destroy()
      delete this.instances[globalUniqueIdentifier]
    }
  }
}


const backendHandler = new BackendHandler()
export default backendHandler
