<script setup>
import { ref } from 'vue'
import i18n from '../../plugins/i18n'
import WYSIWYG from '@/components/WYSIWYG.vue'
import { useIncidentStore } from '@/store/incident.js'
import { useToast } from 'vue-toastification';
const toast = useToast();

const { t } = i18n.global

const props = defineProps({
  incident: { type: Object, default: () => ({}) },
})

const emit = defineEmits(['updateIncident'])

const showHideDialog = ref(false)
const showUnhideDialog = ref(false)
const hideReason = ref('')
const loading = ref(false)
const form = ref(null)
async function hideIncident() {
  form.value.validate()
  if(!form.value.isValid) return
  loading.value = true
  const result = await useIncidentStore().hideIncident(props.incident.incidentId, hideReason.value)
  if(result) {
    emit('updateIncident', result)
    showHideDialog.value = false
    toast.success(t('toast.incident.hide.success'))
  } else {
    toast.error(t('toast.incident.hide.error'))
  }
  loading.value = false
}

async function unhideIncident() {
  loading.value = true
  const result = await useIncidentStore().unHideIncident(props.incident.incidentId)
  if(result) {
    emit('updateIncident', result)
    showUnhideDialog.value = false
    toast.success(t('toast.incident.unhide.success'))
  } else {
    toast.error(t('toast.incident.unhide.error'))
  }
  loading.value = false
}
</script>

<template>
  <v-btn
    :loading="loading"
    v-if="incident.hidden === true"
    variant="outlined"
    class="rounded-pill"
    @click="unhideIncident"
  >
    {{ $t('incidents.hidden.operations.unhide') }}
  </v-btn>
  <v-btn
    :loading="loading"
    v-if="incident.hidden !== true"
    variant="outlined"
    class="rounded-pill"
  >
    {{ $t('incidents.hidden.operations.hide') }}
    <v-dialog
      v-model="showHideDialog"
      activator="parent"
      max-width="800"
      :persistent="true"
    >
      <v-layout-card :loading="loading" :disabled="loading">
        <v-card-title>Hide Incident</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <WYSIWYG
              v-model="hideReason"
              label="Reason"
              :rules="[v => !!v || 'Reason is required']"
            />
          </v-form>

        </v-card-text>
        <v-card-actions class="justify-end" >
          <v-btn
            variant="outlined"
            color="error"
            @click="showHideDialog = false"
          >
            {{ $t('general_interface.buttons.cancel') }}
          </v-btn>
          <v-btn
            variant="flat"
            color="success"
            @click="hideIncident"
          >
            {{ $t('incidents.hidden.operations.hide') }}
          </v-btn>
        </v-card-actions>
      </v-layout-card>
    </v-dialog>
  </v-btn>
</template>

<style scoped>

</style>
