import { defineStore } from 'pinia'
import backendHandler from '@/BackendAPI';
import { useInstanceStore } from '@/store/backendInstances';

export const useMeasurementStore = defineStore('measurements', {
  state: () => ({}),
  persist: false,
  share: {
    enable: false,
    initialize: false,
  },
  getters: {},
  actions: {

    async loadUsedLabels() {
      const response = await backendHandler.get(useInstanceStore().selectedInstanceId, { path: '/api/data/labels' })
      if (!response.ok) return [];
      return response.data
    },

    async loadGenericMeasurement(measurement, query, abortSignal = undefined) {
      const dataResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: '/api/data/' + measurement,
        data: {
          iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
          query: query,
        },
        isPathBound: true,
        signal: abortSignal,
      })
      if (!dataResponse.ok) return null;
      return dataResponse.data;
    },

    async loadSimpleMeasurementForSingleCamera(
      measurement,
      value,
      cameraId,
      timeQuery,
      timeResolution,
      xAggregation = 'mean') {
      return this.loadGenericMeasurement(measurement, {
        columns: { [value]: `${xAggregation}@y`, 'time@x': true },
        where: { camera: cameraId },
        time: timeQuery,
        timeResolution: timeResolution + 'ms',
      })
    },
    loadSimpleMeasurementForMultipleBarns(
      measurement,
      value,
      vmsInstanceNames,
      timeQuery,
      timeResolution,
      aggregate = true,
      xAggregation = 'mean', where) {
      return this.loadGenericMeasurement(measurement, {
        columns: { [value]: `${xAggregation}@y`, 'time@x': true },
        where: { vmsInstanceName: vmsInstanceNames, ...where },
        time: timeQuery,
        timeResolution: timeResolution + 'ms',
        groupByTags: aggregate === true ? undefined : 'vmsInstanceName',
      })
    },

    async loadSimpleMeasurementForMultipleCameras(
      measurement,
      value,
      cameraIds,
      timeQuery,
      timeResolution,
      aggregate = true,
      xAggregation = 'mean',
      skipTimeResolution=false,
      additionalWhereFilters = {},
      abortSignal=undefined) {
      const query = {
        columns: { [value]: `${xAggregation}@y`, 'time@x': true },
        where: { camera: cameraIds },
        time: timeQuery,
        timeResolution: timeResolution + 'ms',
        groupByTags: aggregate === true ? undefined : 'camera',
      }

      if (Object.keys(additionalWhereFilters).length > 0) {
        query.where = { ...query.where, ...additionalWhereFilters }
      }

      return this.loadGenericMeasurement(measurement, query, abortSignal)
    },

    async loadSimpleMeasurementForAllCameras(
      measurement,
      value,
      timeQuery,
      timeResolution,
      aggregate = true,
      xAggregation = 'mean',
      skipTimeResolution=false,
      additionalWhereFilters = {},
      abortSignal=undefined) {
      return this.loadGenericMeasurement(measurement, {
        columns: { [value]: `${xAggregation}@y`, 'time@x': true },
        time: timeQuery,
        where: additionalWhereFilters,
        timeResolution: timeResolution + 'ms',
        groupByTags: aggregate ? undefined : 'camera',
      }, abortSignal)
    },

    async loadRegionSumData(
      timeQuery,
      timeResolution,
      property,
      specificValues=undefined,
      aggregate=true,
      abortSignal=undefined) {

      const query = {
        columns: { 'meanCount': 'mean@y', 'time@x': true },
        time: timeQuery,
        timeResolution: timeResolution + 'ms',
        groupByTags: aggregate ? undefined : 'value',
        where: { property: property },
      }
      if(specificValues?.length) {
        query.where.value = specificValues.specificValues
      }
      return this.loadGenericMeasurement('regionSums', query, abortSignal)
    },

    async loadLabelCountsForMultipleCameras(
      cameraIds,
      timeQuery,
      timeResolution,
      labels = undefined,
      aggregateCameras = true,
      aggregateLabels = true,
      abortSignal=undefined) {
      const dataResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: '/api/data/labelCounts', data: {
          iReadTheDocumentationAndReallyWantToRequestAllThatData: true,
          labels,
          time: timeQuery,
          timeResolution: timeResolution + 'ms',
          groupByLabel: !aggregateLabels,
          groupByCamera: !aggregateCameras,
          cameras: cameraIds,
        },
        isPathBound: true,
        signal: abortSignal,
      })
      if (!dataResponse.ok) return null;
      return dataResponse.data;
    },

    async loadGridScoreDerivationMeasurement(
      timeQuery,
      timeResolution,
      cameraIds,
      aggregateCameras = true,
      abortSignal) {
      const query = [
        {
          columns: { gridScore: 'spread@inner' },
          time: timeQuery,
          timeResolution: '10m',
          groupByTags: ['camera'],
        },
        {
          columns: { inner: 'max@y', 'time@x': true },
          timeResolution: timeResolution + 'ms',
        },
      ]
      if(cameraIds) {
        query[0].where = { camera:cameraIds }
      }
      if (!aggregateCameras) {
        query[1].groupByTags = ['camera']
      }
      return this.loadGenericMeasurement('distribution', query, abortSignal)
    },

    async loadRegionCounts(
      timeQuery,
      timeResolution,
      labels = undefined,
      aggregateLabels = true,
      regionType = undefined,
      regionSpecifications = undefined,
      groupByPen = false,
      abortSignal=undefined) {
      const query = [
        {
          columns: { regions: 'sum@inner' },
          time: timeQuery,
          timeResolution: '1m',
          groupByTags: [regionType],
          where: { [regionType]: regionSpecifications },
        },
        {
          columns: { inner: 'mean@y', 'time@x': true },
          timeResolution: timeResolution + 'ms',
          groupByTags: [regionType],
          where: {},
        },
      ]
      if (!aggregateLabels) {
        query[0].groupByTags.push('label')
        query[1].groupByTags.push('label')
      }
      if(groupByPen) {
        query[0].groupByTags.push('pen')
        query[1].groupByTags.push('pen')
      }
      if(labels) {
        query[0].where.label = labels
      }

      return this.loadGenericMeasurement('regions', query, abortSignal)
    },

  },

})
