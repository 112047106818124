<script setup>

import BaseChart from '@/components/charts/BaseChart.vue'
import { loadRegionSumMeasurement } from '@/composables/charts'

const props = defineProps({
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  defaultCollapsed: { type: Boolean, default: false },
  aggregate: { type: Boolean, default: false },
  property: { type: String, default: '' },
  specificValues: { type: Array, default: () => [] },
  incidentAnnotationProviders: { type: Array, default: () => [] },
  height: { type: Number, default: 400 },
})

async function dataLoader(timeSetup, abortSignal) {
  return await loadRegionSumMeasurement({
    timeResolution: timeSetup.timeResolution,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregate: props.aggregate,
    property: props.property,
    specificValues: props.specificValues,
  }, abortSignal)
}

</script>

<template>
  <BaseChart
    :default-collapsed="defaultCollapsed"
    :data-loader="dataLoader"
    :height="height"
    :title="title"
    :incident-annotation-providers="incidentAnnotationProviders"
    :subtitle="subtitle"
  />
</template>

<style scoped>

</style>
